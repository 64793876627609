import React from 'react';

const BigTriangle = () => {

    const styles = {
        container: {
            position: 'absolute',
            height: '100%',
            width: '100%',
            top: '0',
            left: '0',
            zIndex: '-1'
        },
        triangle: {
            width: '0',
            height: '0',
            borderStyle: 'solid',
            borderWidth: '0 0 100vh 100vw',
            borderColor: 'transparent transparent rgba(255, 255, 255, 0.15) transparent',
            // borderColor: 'transparent transparent rgba(1, 121, 255, 0.25) transparent',
            position: 'absolute',
            bottom: '0',
            right: '0',
        }
    }

    return (
        <div
            style={styles.container}
        >
            <div style={styles.triangle}>

            </div>
        </div>
    );
};

export default BigTriangle;