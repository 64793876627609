import React from 'react';
import AuthPage from './AuthPage';

const JobsPage = () => {
    return (
        <AuthPage>
            <div>
                Software Engineering
                <br />
                UI/UX Designer
                <br />
                Sales
            </div>
        </AuthPage>
    );
};

export default JobsPage;