import ACTIONS from '../actions';

const initialState = {
    menu_visible: true,
    info_visible: false,
    last_chat: null
}

const inboxReducer = (state = initialState, action) => {
    switch(action.type) {
        case ACTIONS.TOGGLE_INFO:
            return {
                ...state,
                info_visible: action.payload
            }
        case ACTIONS.TOGGLE_MENU:
            return {
                ...state,
                menu_visible: action.payload
            }
        case ACTIONS.SET_LAST_CHAT:
            return {
                ...state,
                last_chat: action.payload
            }
        default:
            return state
    }
}

export default inboxReducer;