import ACTIONS from '../actions';

const initialState = null

const historyReducer = (state = initialState, action) => {
    switch(action.type) {
        case ACTIONS.SET_LAST_LOCATION:
            return action.payload
        default:
            return state
    }
}

export default historyReducer;