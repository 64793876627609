import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import axios from 'axios';
import ActivationError from './ActivationError';
import ActivationSuccess from './ActivationSuccess';

const ActivationEmail = () => {

    const {activation_token} = useParams();
    const [err, setErr] = useState('');
    const [user, setUser] = useState('');

    useEffect(() => {
        if(activation_token) {
            console.log({activation_token})
            const activationEmail = async() => {
                try {
                    const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/users/activate`, {token: activation_token});
                    setUser(res.data.user)
                } catch (err) {
                    err.response.data.message && setErr(err.response.data.message);
                }
            }
            activationEmail();
        }
    },[activation_token]);

    return (
        <div style={{color: '#000'}}>
            {err && <ActivationError />}
            {user && <ActivationSuccess user={user} />}
        </div>
    );
};

export default ActivationEmail;