import React from 'react';
import { useTheme } from '../../theme/useTheme';
import ResetPassword from './auth/ResetPassword';

const ResetPasswordPage = () => {

    const {landing} = useTheme()

    const styles = {
        container: {
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: landing.hero.background,
            color: landing.text
        }
    }

    return (
        <div
            style={styles.container}
        >
            <ResetPassword />
        </div>
    );
};

export default ResetPasswordPage;