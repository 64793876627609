import ACTIONS from '../actions';

const contentPage = {
    open: false,
    page: ''
}

const contentPageReducer = (state = contentPage, action) => {
    switch(action.type) {
        case ACTIONS.SET_CONTENT_PAGE:
            return {
                ...state,
                ...action.payload
            }
        case ACTIONS.CLEAR_CONTENT_PAGE:
            return contentPage
        default:
            return state
    }
}

export default contentPageReducer;