import ACTIONS from '../actions';

const lastList = '';

const lastListReducer = (state = lastList, action) => {
    switch(action.type) {
        case ACTIONS.GET_LAST_LIST:
            return action.payload
        case ACTIONS.CLEAR_LAST_LIST:
            return ''
        default:
            return state
    }
}

export default lastListReducer;