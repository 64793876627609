import React, {useState} from 'react';
import { useTheme } from '../../theme/useTheme';

const initialState = {
    hovering: false
}

const FakeLink = (props) => {

    const [state, setState] = useState(initialState)
    const {linkDefault, linkHover} = useTheme()

    const styles = {
        link: {
            color: state.hovering ? linkHover : linkDefault,
            textDecoration: state.hovering ? 'underline' : 'none',
            cursor: 'pointer',
            fontWeight: '600'
        }
    }
    return (
        <span
            onClick={() => props.onClick()}
            style={styles.link}
            onMouseEnter={() => setState({...state, hovering: true})}
            onMouseLeave={() => setState({...state, hovering: false})}
        >
            {props.children}
        </span>
    );
};

export default FakeLink;