import React from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '../../theme/useTheme';

const SignUpButton = (props) => {

    const {landing} = useTheme()

    const styles = {
        container: {
            height: '32px',
            padding: '0 15px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: landing.beta_btn.background.default,
            color: landing.beta_btn.text,
            fontSize: '14px',
            fontWeight: '600',
            borderRadius: '4px',
            textDecoration: 'none'
        }
    }

    return (
        <Link
            to={props.to}
            style={styles.container}
        >
            {props.title}
        </Link>
    );
};

export default SignUpButton;