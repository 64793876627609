import React, { useEffect, Suspense, lazy, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import axios from 'axios';
import {login} from './redux/actions/authAction';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/App.scss';
import PageLoading from './components/global/PageLoading';
import LandingRoutes from './routes/LandingRoutes';
import BetaWall from './components/landing/auth/BetaWall';
import { setTheme } from './redux/actions/themeActions';
// import { setMobile } from './redux/actions/mobileActions';

const AppRoutes = lazy(() => import('./routes/AppRoutes'));

const App = () => {

    const dispatch = useDispatch()
    const token = useSelector(state => state.token)
    const auth = useSelector(state => state.auth)

    // const handleLogout = async () => {
    //     try {
    //         await axios.get(`${process.env.REACT_APP_API_BASE_URL}/user/logout`);
    //         dispatch(logout());
    //         dispatch({type: 'CLEAR_TOKEN'});
    //         localStorage.removeItem('firstLogin');
    //     } catch (error) {
    //     }
    // }

    useEffect(() => {
        const firstLogin = localStorage.getItem('firstLogin')
        if(firstLogin) {
            const getToken = async() => {
                try {
                    const res = await axios.post(`/user/refresh_token`, null)
                    dispatch({type: 'GET_TOKEN', payload: res.data.access_token})
                } catch (error) {
                    console.log(error.message)
                }
            }
            getToken()
        }
    },[dispatch])

    useEffect(() => {
        if(token) {
            dispatch(login())
        // } else if(auth.isLogged) {
        //     handleLogout()
        }
    },[token, dispatch])

    const theme = useSelector(state => state.theme)

    useEffect(() => {
        let html = document.getElementsByTagName('html')[0]
        if(theme.auto) {
            let prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
            if(prefersDark) {
                html.classList.add("night-mode")
                dispatch(setTheme('NIGHT'))
            } else {
                html.classList.remove("night-mode")
                dispatch(setTheme('DAY'))
            }
        } else if(theme.theme === 'DAY') {
            html.classList.remove("night-mode")
        } else if(theme.theme === 'NIGHT') {
            html.classList.add("night-mode")
        }
    }, [])



    const [dimensions, setDimensions] = useState({
        window_height: window.innerHeight,
        window_width: window.innerWidth
    })

    // const mobile = useSelector(state => state.mobile)

    // const handleResize = () => {
    //     let size
    //     let width = window.screen.width
    //     if(width >= 1400) {
    //         size = 'xxl'
    //     } else if(width >= 1200) {
    //         size = 'xl'
    //     } else if(width >= 992) {
    //         size = 'lg'
    //     } else if(width >= 768) {
    //         size = 'md'
    //     } else if(width >= 576) {
    //         size = 'sm'
    //     } else {
    //         size = 'xs'
    //     }

    //     let media_size = size === 'xs' || size === 'sm' || size === 'md'
    //     let window_height = window.innerHeight
    //     let window_width = window.innerWidth
    //     return {media_size, window_height, window_width}
    // }

    // useEffect(() => {
    //     const resizeListener = () => {
    //         let {media_size, window_height, window_width} = handleResize()
    //         setDimensions(() => ({...dimensions, window_height, window_width}))
    //         dispatch(setMobile(media_size))
    //     };
    //     resizeListener()
    //     window.addEventListener('resize', resizeListener);
    //     return () => {
    //       window.removeEventListener('resize', resizeListener);
    //     }
    // }, [])

    // useEffect(() => {
    //     const visit = async() => {
    //         try {
    //             await axios.post(`${process.env.REACT_APP_API_BASE_URL}/user/visit`, {
    //                 headers: {Authorization: token}
    //             });
    //         } catch (error) {
    //             console.log(error)
    //         }
    //     }

    //     visit()
    // }, [])

    function Routes(props) {
        let auth = props.auth;

        if (!auth.isLogged) {
            return <LandingRoutes />;
        } else if (!auth.user.isBeta) {
            return <BetaWall />;
        } else {
            return <AppRoutes />;
        }
    }

    return(
        <Suspense fallback={<PageLoading />}>
            <BrowserRouter>
                <Routes auth={auth}/>
            </BrowserRouter>
        </Suspense>
    );
}

export default App;
