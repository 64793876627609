import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '../../theme/useTheme';
import { useMediaQuery } from 'react-responsive';

const AuthBackBtn = () => {

    const md = useMediaQuery({ query: '(min-width: 768px)' })

    const [state, setState] = useState({
        hovering: false
    })

    const {landing} = useTheme()

    const styles = {
        container: {
            height: '50px',
            width: '50px',
            backgroundColor: state.hovering ? landing.header.back_button.background.hover : landing.header.back_button.background.default,
            cursor: 'pointer',
            position: 'fixed',
            top: '15px',
            left: '15px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            transition: 'background-color 0.2s ease',
            borderRadius: '50%',
            textDecoration: 'none'
        },
        icon: {
            fontSize: '20px',
            color: landing.header.back_button.icon
        },
        label: {
            position: 'absolute',
            left: '60px',
            fontSize: '15px',
            fontWeight: '600',
            fontStyle: 'italic',
            pointerEvents: 'none',
            color: landing.header.back_button.esc
        }
    }

    return (
        <Link
            to="/"
            style={styles.container}
            onMouseEnter={() => setState({...state, hovering: true})}
            onMouseLeave={() => setState({...state, hovering: false})}
        >
            <span className="material-icons-outlined" style={styles.icon}>
                close
            </span>

            {md && <div style={styles.label}>esc</div>}
        </Link>
    );
};

export default AuthBackBtn;