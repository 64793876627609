import React from 'react';
import {useTheme} from '../../../theme/useTheme';

const NotificationFlash = (props) => {

    const {colors} = useTheme();

    const styles = {
        container: {
            width: '100%',
            height: '40px',
            position: 'relative',
            marginBottom: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            background: colors.red,
            fontSize: '14px',
            fontWeight: '600',
            color: '#fff',
            borderRadius: '6px',
        },
    }

    return (
        <div style={styles.container}>
            {props.message}
        </div>
    );
};

export default NotificationFlash;