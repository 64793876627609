import React from 'react';
import { useTheme } from '../../theme/useTheme';
import AuthBackBtn from './AuthBackBtn';

const AuthHeader = (props) => {

    const {landing} = useTheme()

    const styles = {
        container: {
            height: '50px',
            width: '100%',
            position: 'fixed',
            top: '0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'row'
        },
        title: {
            fontSize: '24px',
            fontWeight: '800',
            color: landing.header.text,
        }
    }

    return (
        <div style={styles.container}>

            <AuthBackBtn />

            {/* <div style={styles.title}>
                {props.title}
            </div> */}
            
        </div>
    );
};

export default AuthHeader;