import React from 'react';

const LocationCredit = () => {

    const styles = {
        container: {
            fontSize: '12px',
            fontWeight: '500',
            marginLeft: '15px',
            color: '#6B7D8A'
        },
        icon: {
            fontSize: '14px',
            top: '2px',
            marginRight: '5px',
            position: 'relative'
        }
    }

    return (
        <div style={styles.container}>
            {/* <span className="material-icons-round" style={styles.icon}>
                place
            </span> */}
            Made in Gig Harbor, WA
        </div>
    );
};

export default LocationCredit;