import ACTIONS from '../actions';

const initialState = {
    id: null,
    user: {
        src: null,
        title: null
    },
    privacy: 0,
    starred: false,
    title: "",
}

const projectsReducer = (state = initialState, action) => {
    switch(action.type) {
        case ACTIONS.PROJECT_META:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state
    }
}

export default projectsReducer;