import ACTIONS from '../actions';

const is_mobile = true;

const mobileReducer = (state = is_mobile, action) => {
    switch(action.type) {
        case ACTIONS.SET_MOBILE:
            return action.payload.is_mobile
        default:
            return state
    }
}

export default mobileReducer;