import ACTIONS from '../actions';

const initialState = {
    percentage: 75,
    width: 1000,
    height: 1000,
    fontSize: 16,
    fontFamily: "'Courier Prime', 'Courier', monospace, serif",
    lineHeight: 30,
    inch: 1,
    default: true,
    offset: 0
}

const pageWidthReducer = (state = initialState, action) => {
    switch(action.type) {
        case ACTIONS.PAGE_WIDTH:
            return {
                ...state,
                percentage: action.payload.percentage,
                width: action.payload.width,
                height: action.payload.height,
                fontSize: action.payload.fontSize,
                lineHeight: action.payload.lineHeight,
                inch: action.payload.inch,
                default: false,
                offset: action.payload.offset
            }
        case ACTIONS.SCROLL_POSITION:
            return {
                ...state,
                scroll_position: action.payload.scroll_position
            }
        default:
            return state
    }
}

export default pageWidthReducer;