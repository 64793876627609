import React from 'react';
import ForgotPassword from './auth/ForgotPassword';
import AuthPage from './AuthPage';

const ForgotPasswordPage = () => {
    return (
        <AuthPage>
            <ForgotPassword />
        </AuthPage>
    );
};

export default ForgotPasswordPage;