import React, {useEffect} from 'react';
import Logout from './Logout';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { enableBeta } from '../../../redux/actions/authAction';
import { useTheme } from '../../../theme/useTheme';

const BetaWall = () => {

    const {activation} = useTheme()

    const token = useSelector(state => state.token)
    const auth = useSelector(state => state.auth)
    const {user} = auth
    const history = useHistory()
    const dispatch = useDispatch()

    useEffect(() => {
        const check_beta_status = async() => {
            try {
                await axios.get(`${process.env.REACT_APP_API_BASE_URL}/user/${user.username}`, {
                    headers: {Authorization: token}
                }).then((res) => {
                    if(res.data.user.isBeta) {
                        dispatch(enableBeta())
                        history.push('/')
                    }
                });
            } catch (error) {
                console.log(error)
            }
        }

        check_beta_status()
    }, [])

    const styles = {
        container: {
            backgroundColor: activation.background,
            height: '100vh',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
        },
        content: {
            color: activation.text,
            width: '400px',
            textAlign: 'center'
        },
        title: {
            fontSize: '36px',
            fontWeight: '900',
            marginTop: '-100px',
            marginBottom: '20px'
        },
        text: {
            fontSize: '18px',
            fontWeight: '500',
        },
        logout: {
            position: 'fixed',
            top: '-10px',
            right: '15px',
        }
    }

    const name = user.displayName.split(' ')[0]

    return (
        <div style={styles.container}>
            <div style={styles.content}>
                <div style={styles.title}>
                    Howdy, {name}!
                </div>
                <div style={styles.text}>
                    We're in beta mode and only granting limited access. We'll shoot you an email when your account gets approved!
                </div>
            </div>
            <div style={styles.logout}>
                <Logout />
            </div>
        </div>
    );
};

export default BetaWall;