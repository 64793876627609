import React from 'react';
import {Link} from 'react-router-dom';
import { useTheme } from '../../../theme/useTheme';

const ActivationError = () => {

    const {activation} = useTheme()

    const styles = {
        container: {
            height: '100vh',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            backgroundColor: activation.background
        },
        title: {
            fontSize: '26px',
            fontWeight: '600',
            color: activation.text
        },
        link: {
            fontSize: '14px',
            fontWeight: '600',
            color: activation.text
        },
        icon: {
            fontSize: '16px',
            marginRight: '5px',
            top: '3px',
            position: 'relative',
            color: activation.text
        }
    }

    return (
        <div style={styles.container}>
            <span style={styles.title}>
                The link you clicked is no good
            </span>
            <span>
                <Link to="/sign-up" style={styles.link}>
                    <span className="material-icons-round" style={styles.icon}>
                        refresh
                    </span>
                    start over
                </Link>
            </span>
        </div>
    );
};

export default ActivationError;