import ACTIONS from '../actions';

const initialState = {
    index: null,
    type: null,
    offset: {
        start: null,
        end: null
    },
    fullscreen: false
}

const currentProjectReducer = (state = initialState, action) => {
    switch(action.type) {
        case ACTIONS.CURSOR_POS:
            return {
                ...state,
                ...action.payload
            }
        case ACTIONS.ELEMENT_TYPE_PROJECT:
            return {
                ...state,
                type: action.payload.type
            }
        case ACTIONS.BLUR:
            return initialState
        case ACTIONS.TOGGLE_PICKER:
            return {
                ...state,
                picker_open: action.payload.open
            }
        default:
            return state
    }
}

export default currentProjectReducer;