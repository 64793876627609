import React from 'react';
import FakeLink from '../../global/FakeLink';
import Countdown from 'react-countdown';
import {loginWithData} from '../../../redux/actions/authAction';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import { useTheme } from '../../../theme/useTheme';

const ActivationSuccess = (props) => {

    const {activation} = useTheme()

    const dispatch = useDispatch();
    const history = useHistory();

    const loginRedirect = async() => {
        localStorage.setItem('firstLogin', true);
        dispatch(loginWithData(props.user));
        history.push('/');
    }

    const styles = {
        container: {
            height: '100vh',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            backgroundColor: activation.background,
            color: activation.text
        },
        countdown: {
            fontWeight: '600'
        },
        title: {
            fontSize: '26px',
            fontWeight: '600',
            color: activation.text
        },
        link: {
            fontSize: '14px',
            fontWeight: '600',
            color: activation.text
        },
    }

    const renderer = ({seconds}) => {
        return <span>{seconds}</span>
    };

    return (
        <div style={styles.container}>

            <span style={styles.title}>
                Redirecting in <Countdown 
                    date={Date.now() + 3000} 
                    onComplete={loginRedirect}
                    zeroPadTime={1}
                    renderer={renderer}
                />
            </span>
            <span style={styles.link}>
                or <FakeLink onClick={loginRedirect}>enter now</FakeLink>
            </span>

        </div>
    );
};

export default ActivationSuccess;