import ACTIONS from '../actions';

const initialState = []

const missionsReducer = (state = initialState, action) => {
    switch(action.type) {
        case ACTIONS.REFRESH_MISSIONS:
            return action.payload.new_missions
        default:
            return state
    }
}

export default missionsReducer;