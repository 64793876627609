import ACTIONS from '../actions';

const initialState = {
    bucket_visible: true,
}

const milestoneReducer = (state = initialState, action) => {
    switch(action.type) {
        case ACTIONS.TOGGLE_BUCKET:
            return {
                ...state,
                bucket_visible: action.payload
            }
        default:
            return state
    }
}

export default milestoneReducer;