import {useSelector} from 'react-redux';
import {day} from './themes/day';
import {night} from './themes/night';

export function useTheme() {

    const active_theme = useSelector(state => state.theme);

    switch(active_theme.theme) {
        case 'DAY':
            return day
        case 'NIGHT':
            return night
        default:
            return day
    }
}