import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {setTheme, setAuto} from '../../redux/actions/themeActions';
import { useTheme } from '../../theme/useTheme';

const ThemeToggle = () => {
    
    const {theme_toggle} = useTheme()

    const theme = useSelector(state => state.theme)

    const [state, setState] = useState({
        hovering: false
    })

    const dispatch = useDispatch()

    const styles = {
        container: {
            cursor: 'pointer',
            color: theme_toggle.text.default,
            // color: state.hovering ? theme_toggle.text.hover : theme_toggle.text.default,
            transition: 'color 0.2s ease',
            padding: '3px 10px',
            fontSize: '13px',
            fontWeight: '600',
            textDecoration: 'none',
            height: '45px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        icon: {
            fontSize: '13px',
            marginRight: '8px'
        }
    }

    const handleClick = () => {
        let html = document.getElementsByTagName('html')[0]
        if(theme.auto) {
            dispatch(setAuto(false))
            html.classList.remove("night-mode")
            dispatch(setTheme('DAY'))
        } else if(theme.theme === 'DAY') {
            dispatch(setAuto(false))
            html.classList.add("night-mode")
            dispatch(setTheme('NIGHT'))
        } else if(theme.theme === 'NIGHT') {
            html.classList.remove("night-mode")
            dispatch(setTheme('DAY'))
            dispatch(setAuto(true))
        }
    }

    return (
        <div
            style={styles.container}
            onMouseEnter={() => setState({...state, hovering:true})}
            onMouseLeave={() => setState({...state, hovering:false})}
            onClick={handleClick}
        >
            <span className="material-icons-round" style={styles.icon}>
                dark_mode
            </span>
            
            {theme.auto ? 'Auto' : theme.theme === 'DAY' ? 'Off' : 'On'}
        </div>
    );
};

export default ThemeToggle;