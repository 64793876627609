const ACTIONS = {
    LOGIN: 'LOGIN',
    LOGIN_WITH_DATA: 'LOGIN_WITH_DATA',
    LOGOUT: 'LOGOUT',
    GET_TOKEN: 'GET_TOKEN',
    CLEAR_TOKEN: 'CLEAR_TOKEN',
    GET_USER: 'GET_USER',
    THEME: 'THEME',
    THEME_AUTO: 'THEME_AUTO',
    SIDEBAR: 'SIDEBAR',
    PAGE_WIDTH: 'PAGE_WIDTH',
    FONT_WIDTH: 'FONT_WIDTH',
    NEW_FILM_PROJECT: 'NEW_FILM_PROJECT',
    PARAGRAPH_TYPE: 'PARAGRAPH_TYPE',
    UPDATE_PARAGRAPH: 'UPDATE_PARAGRAPH',
    NEW_PARAGRAPH_BEFORE: 'NEW_PARAGRAPH_BEFORE',
    NEW_PARAGRAPH_AFTER: 'NEW_PARAGRAPH_AFTER',
    SPLIT_PARAGRAPH: 'SPLIT_PARAGRAPH',
    REMOVE_PARAGRAPH: 'REMOVE_PARAGRAPH',
    MERGE_PARAGRAPHS: 'MERGE_PARAGRAPHS',
    REMOVE_CHARACTER: 'REMOVE_CHARACTER',
    CLEAR_PARAGRAPHS: 'CLEAR_PARAGRAPHS',
    CURSOR_POS: 'CURSOR_POS',
    SIDEBAR_OPEN: 'SIDEBAR_OPEN',
    SIDEBAR_HOVER: 'SIDEBAR_HOVER',
    SIDEBAR_PROJECT_OPEN: 'SIDEBAR_PROJECT_OPEN',
    SET_LAST_LOCATION: 'SET_LAST_LOCATION',
    ELEMENT_TYPE_PROJECT: 'ELEMENT_TYPE_PROJECT',
    ELEMENT_TYPE_PARAGRAPH: 'ELEMENT_TYPE_PARAGRAPH',
    BLUR: 'BLUR',
    SEARCH_QUERY: 'SEARCH_QUERY',
    PROJECT_META: 'PROJECT_META',
    UPDATE_PROJECT_TITLE: 'UPDATE_PROJECT_TITLE',
    STAR: 'STAR',
    SCROLL_POSITION: 'SCROLL_POSITION',
    UPDATE_RECTS: 'UPDATE_RECTS',
    LOG_KEY: 'LOG_KEY',
    TOGGLE_PICKER: 'TOGGLE_PICKER',
    SWITCH_MODE: 'SWITCH_MODE',
    SWITCH_TAB: 'SWITCH_TAB',
    CREATE_TEAM: 'CREATE_TEAM',
    CREATE_LIST: 'CREATE_LIST',
    TOGGLE_SUBSIDEBAR: 'TOGGLE_SUBSIDEBAR',
    ADD_ITEM_TO_LIST: 'ADD_ITEM_TO_LIST',
    UPDATE_LIST: 'UPDATE_LIST',
    UPDATE_LIST_ITEM_TITLE: 'UPDATE_LIST_ITEM_TITLE',
    ADD_CARD_TO_LIST: 'ADD_CARD_TO_LIST',
    TOGGLE_SUBSIDEBAR_HOVER: 'TOGGLE_SUBSIDEBAR_HOVER',
    NEW_TAB: 'NEW_TAB',
    CLOSE_TAB: 'CLOSE_TAB',
    NEW_TASK_LIST: 'NEW_TASK_LIST',
    REFRESH_LISTS: 'REFRESH_LISTS',
    GET_LAST_LIST: 'GET_LAST_LIST',
    CLEAR_LAST_LIST: 'CLEAR_LAST_LIST',
    FETCH_PROJECTS: 'FETCH_PROJECTS',
    UPDATE_AVATAR: 'UPDATE_AVATAR',
    ENABLE_BETA: 'ENABLE_BETA',
    SET_HEADER: 'SET_HEADER',
    CLEAR_HEADER: 'CLEAR_HEADER',
    SET_CONTENT_PAGE: 'SET_CONTENT_PAGE',
    CLEAR_CONTENT_PAGE: 'CLEAR_CONTENT_PAGE',
    FETCH_COMMENTS: 'FETCH_COMMENTS',
    CLEAR_COMMENTS: 'CLEAR_COMMENTS',
    SET_MOBILE: 'SET_MOBILE',
    MODAL_OPEN: 'MODAL_OPEN',
    MODAL_ACTIVE: 'MODAL_ACTIVE',
    MODAL_ANIMATE: 'MODAL_ANIMATE',
    MODAL_DRAGGING: 'MODAL_DRAGGING',
    MODAL_ANIMATE_CONTENT: 'MODAL_ANIMATE_CONTENT',
    INBOX_OPEN: 'INBOX_OPEN',
    PERCENT_ANIMATE: 'PERCENT_ANIMATE',
    DRAG_X_POS: 'DRAG_X_POS',
    DRAGGING: 'DRAGGING',
    INBOX_ANIMATE: 'INBOX_ANIMATE',
    INBOX_ANIMATE_CONTENT: 'INBOX_ANIMATE_CONTENT',
    INBOX_DRAGGING: 'INBOX_DRAGGING',
    CONTAINER_ANIMATE: 'CONTAINER_ANIMATE',
    CONTAINER_DRAGGING: 'CONTAINER_DRAGGING',
    HEADER_ANIMATE_RIGHT_GONE_BUTTON: 'HEADER_ANIMATE_RIGHT_GONE_BUTTON',
    HEADER_ANIMATE_RIGHT_IN_BUTTON: 'HEADER_ANIMATE_RIGHT_IN_BUTTON',
    HEADER_ANIMATE_RIGHT_OUT_BUTTON: 'HEADER_ANIMATE_RIGHT_OUT_BUTTON',
    HEADER_ANIMATE_LEFT_GONE_BUTTON: 'HEADER_ANIMATE_LEFT_GONE_BUTTON',
    HEADER_ANIMATE_LEFT_IN_BUTTON: 'HEADER_ANIMATE_LEFT_IN_BUTTON',
    HEADER_ANIMATE_LEFT_OUT_BUTTON: 'HEADER_ANIMATE_LEFT_OUT_BUTTON',
    HEADER_ANIMATE_LEFT_BUTTON: 'HEADER_ANIMATE_LEFT_BUTTON',
    HEADER_ANIMATE_LEFT_BUTTON_TEXT: 'HEADER_ANIMATE_LEFT_BUTTON_TEXT',
    HEADER_ANIMATE_CENTER_BUTTON: 'HEADER_ANIMATE_CENTER_BUTTON',
    HEADER_ANIMATE_CENTER_BUTTON_TEXT: 'HEADER_ANIMATE_CENTER_BUTTON_TEXT',
    HEADER_ANIMATE_RIGHT_BUTTON: 'HEADER_ANIMATE_RIGHT_BUTTON',
    HEADER_ANIMATE_RIGHT_BUTTON_TEXT: 'HEADER_ANIMATE_RIGHT_BUTTON_TEXT',
    HEADER_ANIMATE_OUT_BUTTON: 'HEADER_ANIMATE_OUT_BUTTON',
    HEADER_ANIMATE: 'HEADER_ANIMATE',
    HEADER_DRAGGING_DIRECTION: 'HEADER_DRAGGING_DIRECTION',
    HEADER_DRAGGING: 'HEADER_DRAGGING',
    DRAG_DIRECTION: 'DRAG_DIRECTION',
    SIDEBAR_SHOW_TRIBES: 'SIDEBAR_SHOW_TRIBES',
    LOAD_TRIBE: 'LOAD_TRIBE',
    REFRESH_TRIBES: 'REFRESH_TRIBES',
    LOAD_MISSION: 'LOAD_MISSION',
    REFRESH_MISSIONS: 'REFRESH_MISSIONS',
    UPDATE_USER_INFO: 'UPDATE_USER_INFO',
    TOGGLE_INFO: 'TOGGLE_INFO',
    TOGGLE_MENU: 'TOGGLE_MENU',
    SET_LAST_CHAT: 'SET_LAST_CHAT',
    TOGGLE_BUCKET: 'TOGGLE_BUCKET',
    APPLY_FILTER: 'APPLY_FILTER',
    LOAD_PROJECT: 'LOAD_PROJECT',
    TOGGLE_STARRED: 'TOGGLE_STARRED',
    TOGGLE_UNREAD: 'TOGGLE_UNREAD',
    TOGGLE_READ: 'TOGGLE_READ',
    TOGGLE_CHAT: 'TOGGLE_CHAT',
    SET_CHAT_ID: 'SET_CHAT_ID',
    TOGGLE_CHAT_MINIMIZE: 'TOGGLE_CHAT_MINIMIZE'
}

export default ACTIONS;