import ACTIONS from '../actions';

const initialState = {
    open: false,
    active: true,
    animate: 1,
    animate_content: 1,
    dragging: false
}

const modalReducer = (state = initialState, action) => {
    switch(action.type) {
        case ACTIONS.MODAL_OPEN:
            return {
                ...state,
                open: action.payload
            }
        case ACTIONS.MODAL_ACTIVE:
            return {
                ...state,
                active: action.payload
            }
        case ACTIONS.MODAL_ANIMATE:
            return {
                ...state,
                animate: action.payload
            }
        case ACTIONS.MODAL_ANIMATE_CONTENT:
            return {
                ...state,
                animate_content: action.payload
            }
        case ACTIONS.MODAL_DRAGGING:
            return {
                ...state,
                dragging: action.payload
            }
        default:
            return state
    }
}

export default modalReducer;