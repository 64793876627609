import React, {useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {showErrMsg, showSuccessMsg} from '../../utils/notification/Notification';
import {loginWithData} from '../../../redux/actions/authAction';
import {useDispatch} from 'react-redux';
import TextInput from '../../global/form/TextInput';
import SubmitButton from '../../global/form/SubmitButton';
import axios from 'axios';
import { useTheme } from '../../../theme/useTheme';
import AuthContainer from '../AuthContainer';

const Login = (props) => {

    const [user, setUser] = useState({
        username: '',
        password: '',
        err: '',
        success: ''
    });
    const dispatch = useDispatch();
    const history = useHistory();

    const {username, password, err, success} = user;

    const handleChangeInput = (name, value) => {
        setUser({...user, [name]:value, err: '', success: ''})
    }

    const handleSubmit = async(e) => {
        e.preventDefault();

        try {

            let auth_token = "Basic " + btoa(`${username}:${password}`)

            await axios.get(`${process.env.REACT_APP_API_BASE_URL}/users/login`, {
                headers: {authentication: auth_token}
            }).then(res => {
                dispatch(loginWithData(res.data.user));
                dispatch({type: 'GET_TOKEN', payload: res.data.token})
                localStorage.setItem('firstLogin', true);
                let url = res.data.user.isBeta ? '/inbox' : '/'
                history.push(url)
            });
        } catch (err) {
            err.response && 
            setUser({...user, err: err.response.data.message, success: ''})
        }
    }

    const {landing} = useTheme()

    const styles = {
        footer: {
            fontSize: '15px',
            fontWeight: '400',
            textAlign: 'center',
            marginTop: '50px',
            color: landing.text
        },
        link: {
            fontWeight: '600',
            color: landing.text,
        }
    }

    return (
        <AuthContainer>

            {err && showErrMsg(err)}
            {success && showSuccessMsg(success)}

            <form
                onSubmit={handleSubmit}
            >

                <TextInput
                    label="Username"
                    required={true}
                    type="text"
                    value={username}
                    onChange={(value) => handleChangeInput('username', value)}
                />

                <TextInput
                    label="Password"
                    required={true}
                    type="password"
                    value={password}
                    onChange={(value) => handleChangeInput('password', value)}
                />

                <SubmitButton>
                    Log In
                </SubmitButton>

                <div style={styles.footer}>

                    <Link to="/forgot-password" style={styles.link}>
                        Forgot Password?
                    </Link>

                    <br />
                    <br />

                    No account? <Link to="/sign-up" style={styles.link}>Sign Up</Link>

                </div>

            </form>
        </AuthContainer>
    );
};

export default Login;
