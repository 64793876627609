import React from 'react';
import ThemeToggle from '../header/ThemeToggle';
import FooterTab from './FooterTab';
import LocationCredit from './LocationCredit';

const MainFooter = () => {

    const styles = {
        container: {
            width: '100%',
            fontSize: '14px',
            marginTop: '50px',
            position: 'absolute',
            bottom: '0'
        },
        inner: {
            width: '100%',
            height: '45px',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            flexDirection: 'row',
            padding: '0 15px'
        },
        section: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: 'row',
        }
    }
    return (
        <div style={styles.container}>
            <div style={styles.inner}>
                <div style={styles.section}>
                    <ThemeToggle />
                </div>
                <div style={styles.section}>
                    <FooterTab
                        to="/mission"
                        label="Mission"
                    />
                </div>
            </div>
        </div>
    );
};

export default MainFooter;