import React from 'react';
import { useMediaQuery } from 'react-responsive';

const AuthContainer = (props) => {

    const md = useMediaQuery({ query: '(min-width: 420px)' })

    const styles = {
        container: {
            width: md ? '400px' : '100%',
            padding: md ? '0' : '10px',
        }
    }

    return (
        <div style={styles.container}>
            {props.children}
        </div>
    );
};

export default AuthContainer;