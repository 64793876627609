import React, {useState} from 'react';
import { useTheme } from '../../../theme/useTheme';

const TextInput = (props) => {

    const [state, setState] = useState({
        hovering: false,
        focused: false
    });
    const {text_input} = useTheme()

    const styles = {
        container: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            textAlign: 'left',
            margin: '10px 0'
        },
        label: {
            fontSize: '15px',
            color: text_input.label,
            width: '100%',
            fontWeight: '600',
            lineHeight: '32px',
            height: '32px',
            display: props.hideLabel ? 'none' : 'block'
        },
        input: {
            width: '100%',
            height: '60px',
            fontSize: '15px',
            fontWeight: '500',
            padding: '0px',
            backgroundColor: text_input.background.default,
            color: text_input.color,
            border: 'none',
            borderBottom: state.focused || state.hovering ? `2px solid ${text_input.border.active}` : `2px solid ${text_input.border.default}`,
            outline: 'none',
            WebkitAppearance: 'none',
            // color: state.focused ?  text_input.textFocused : text_input.text
        }
    }

    const id = props.label.replace(' ','');

    const handleChangeInput = (e) => {
        const {value} = e.target;
        props.onChange(value);
    }

    const handleKeyDown = (e) => {
        const key = e.nativeEvent.key
        if(props.keyActions && (key === 'Enter' || key === 'ArrowUp' || key === 'ArrowDown')) {
            e.preventDefault()
            props.actionKey(key)
        }
    }

    return (
        <div
            style={styles.container}
        >
            {/* <label 
                htmlFor={id}
                style={styles.label}
            >
                {props.label}
            </label> */}

            <input
                type={props.type || 'text'}
                id={id}
                required={props.required}
                value={props.value}
                onChange={handleChangeInput}
                style={{...styles.input, ...props.style}}
                onFocus={() => setState({...state, focused: true})}
                onBlur={() => setState({...state, focused: false})}
                onMouseEnter={() => setState({...state, hovering: true})}
                onMouseLeave={() => setState({...state, hovering: false})}
                className="text-input"
                autoComplete={props.autoComplete}
                onKeyDown={(e) => handleKeyDown(e)}
                placeholder={props.label}
            />

        </div>
    );
};

export default TextInput;