import ACTIONS from '../actions';

const initialState = {
    animatePercent: 1,
    xPos: 0,
    dragging: false,
    inboxOpen: false,
    inboxAnimate: 1,
    inboxAnimateContent: 0,
    inboxDragging: false,
    containerAnimate: 0,
    containerDragging: false,
    headerAnimate: 0,
    headerAnimateRightOutButton: 0,
    headerAnimateRightInButton: 0,
    headerAnimateRightGoneButton: 0,
    headerAnimateLeftOutButton: 0,
    headerAnimateLeftInButton: 0,
    headerAnimateLeftGoneButton: 0,
    headerAnimateLeftButton: 0,
    headerAnimateLeftButtonText: 0,
    headerAnimateCenterButton: 0,
    headerAnimateCenterButtonText: 0,
    headerAnimateRightButton: 0,
    headerAnimateRightButtonText: 0,
    headerAnimateOutButton: 0,
    headerDraggingDirection: 0,
    headerDragging: false
}

const gestureReducer = (state = initialState, action) => {
    switch(action.type) {
        case ACTIONS.PERCENT_ANIMATE:
            return {
                ...state,
                animatePercent: action.payload
            }
        case ACTIONS.DRAG_X_POS:
            return {
                ...state,
                xPos: action.payload
            }
        case ACTIONS.DRAGGING:
            return {
                ...state,
                dragging: action.payload
            }
        case ACTIONS.INBOX_OPEN:
            return {
                ...state,
                inboxOpen: action.payload
            }
        case ACTIONS.INBOX_ANIMATE:
            return {
                ...state,
                inboxAnimate: action.payload
            }
        case ACTIONS.INBOX_ANIMATE_CONTENT:
            return {
                ...state,
                inboxAnimateContent: action.payload
            }
        case ACTIONS.INBOX_DRAGGING:
            return {
                ...state,
                inboxDragging: action.payload
            }
        case ACTIONS.CONTAINER_ANIMATE:
            return {
                ...state,
                containerAnimate: action.payload
            }
        case ACTIONS.CONTAINER_DRAGGING:
            return {
                ...state,
                containerDragging: action.payload
            }
        case ACTIONS.HEADER_ANIMATE:
            return {
                ...state,
                headerAnimate: action.payload
            }
        case ACTIONS.HEADER_ANIMATE_RIGHT_GONE_BUTTON:
            return {
                ...state,
                headerAnimateRightGoneButton: action.payload
            }
        case ACTIONS.HEADER_ANIMATE_RIGHT_IN_BUTTON:
            return {
                ...state,
                headerAnimateRightInButton: action.payload
            }
        case ACTIONS.HEADER_ANIMATE_RIGHT_OUT_BUTTON:
            return {
                ...state,
                headerAnimateRightOutButton: action.payload
            }
        case ACTIONS.HEADER_ANIMATE_LEFT_GONE_BUTTON:
                return {
                    ...state,
                    headerAnimateLeftGoneButton: action.payload
                }
        case ACTIONS.HEADER_ANIMATE_LEFT_IN_BUTTON:
            return {
                ...state,
                headerAnimateLeftInButton: action.payload
            }
        case ACTIONS.HEADER_ANIMATE_LEFT_OUT_BUTTON:
            return {
                ...state,
                headerAnimateLeftOutButton: action.payload
            }
        case ACTIONS.HEADER_ANIMATE_LEFT_BUTTON:
            return {
                ...state,
                headerAnimateLeftButton: action.payload
            }
        case ACTIONS.HEADER_ANIMATE_LEFT_BUTTON_TEXT:
            return {
                ...state,
                headerAnimateLeftButtonText: action.payload
            }
        case ACTIONS.HEADER_ANIMATE_CENTER_BUTTON:
            return {
                ...state,
                headerAnimateCenterButton: action.payload
            }
        case ACTIONS.HEADER_ANIMATE_CENTER_BUTTON_TEXT:
            return {
                ...state,
                headerAnimateCenterButtonText: action.payload
            }
        case ACTIONS.HEADER_ANIMATE_RIGHT_BUTTON:
            return {
                ...state,
                headerAnimateRightButton: action.payload
            }
        case ACTIONS.HEADER_ANIMATE_RIGHT_BUTTON_TEXT:
            return {
                ...state,
                headerAnimateRightButtonText: action.payload
            }
        case ACTIONS.HEADER_ANIMATE_OUT_BUTTON:
            return {
                ...state,
                headerAnimateOutButton: action.payload
            }
        case ACTIONS.HEADER_DRAGGING_DIRECTION:
                return {
                    ...state,
                    headerDraggingDirection: action.payload
                }
        case ACTIONS.HEADER_DRAGGING:
            return {
                ...state,
                headerDragging: action.payload
            }
        default:
            return state
    }
}

export default gestureReducer;