import ACTIONS from '../actions';

const initialState = {
    columns: [
        {
          id: 1,
          title: 'To Do',
          accent: 'red',
          cards: []
        },
        {
          id: 2,
          title: 'In Progress',
          accent: 'orange',
          cards: []
        },
        {
          id: 3,
          title: 'In Review',
          accent: 'yellow',
          cards: []
        },
        {
          id: 4,
          title: 'Complete',
          accent: 'green',
          cards: []
        }
    ]
}

const listReducer = (state = initialState, action) => {
    var new_columns
    switch(action.type) {
        case ACTIONS.ADD_ITEM_TO_LIST:
            return {
                columns: [
                    ...state.columns
                ]
            }
        case ACTIONS.UPDATE_LIST:
            return action.payload.new_list
        case ACTIONS.UPDATE_LIST_ITEM_TITLE:
            new_columns = state.columns.map((item, index) => {
                if (item.id !== action.payload.index) return item
                item.title = action.payload.new_title
                return item
            })
            return {
                columns: new_columns
            }
        case ACTIONS.ADD_CARD_TO_LIST:
            new_columns = state.columns.map((item, index) => {
                if (item.id !== action.payload.index) return item
                var card = {
                    id: 1,
                    card_id: '1234',
                    title: action.payload.title,
                    description: null,
                    due_date: null
                }
                item.cards = [card, ...item.cards]
                return item
            })
            return {
                columns: new_columns
            }
        default:
            return state
    }
}

export default listReducer;