import ACTIONS from '../actions';

const initialState = []

const tribesReducer = (state = initialState, action) => {
    switch(action.type) {
        case ACTIONS.REFRESH_TRIBES:
            return action.payload.new_tribes
        default:
            return state
    }
}

export default tribesReducer;