import ACTIONS from '../actions';

const initialState = {
    expanded: true,
    min: 1,
    max: 10,
    count: 276,
    projects: [
        {
            label: "Second",
            message_read: true,
            starred: false,
            read: true,
            id: "1234",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis feugiat, elit eget sollicitudin aliquet, nisl ipsum luctus sapien, scelerisque tincidunt nulla est et nibh. Sed nec urna non nibh tincidunt facilisis ut et tellus. Maecenas faucibus pharetra diam. Mauris vehicula sit amet nisi a condimentum. Mauris eget viverra nunc. Pellentesque a finibus diam. Pellentesque dictum felis quam, eget efficitur lectus accumsan a. Aliquam nec rutrum est. Aenean rhoncus posuere leo, sit amet egestas urna pretium in. Pellentesque nec ante sed leo volutpat ullamcorper id sed lectus. Aliquam ac hendrerit libero. Duis auctor interdum magna eu laoreet. Nulla erat eros, ultricies in quam a, euismod finibus sem. Maecenas vulputate nunc et molestie malesuada.",
            last_message: "Blah blah blah",
            user_to: {
                name: 'Summer Films',
                username: 'summer'
            },
            user_from: {
                name: 'Michael Scott',
                username: 'michael_scott'
            },
            amount: 700000,
            files: [],
            steps: [
                {
                    label: 'Agree',
                    page: 'agree',
                    status: 'complete',
                },
                {
                    label: 'Work',
                    page: 'work',
                    status: 'waiting'
                },
                {
                    label: 'Done',
                    page: 'done',
                    status: 'not_started'
                },
            ],
        },
        {
            label: "Third",
            message_read: true,
            starred: false,
            read: false,
            id: "1234",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis feugiat, elit eget sollicitudin aliquet, nisl ipsum luctus sapien, scelerisque tincidunt nulla est et nibh. Sed nec urna non nibh tincidunt facilisis ut et tellus. Maecenas faucibus pharetra diam. Mauris vehicula sit amet nisi a condimentum. Mauris eget viverra nunc. Pellentesque a finibus diam. Pellentesque dictum felis quam, eget efficitur lectus accumsan a. Aliquam nec rutrum est. Aenean rhoncus posuere leo, sit amet egestas urna pretium in. Pellentesque nec ante sed leo volutpat ullamcorper id sed lectus. Aliquam ac hendrerit libero. Duis auctor interdum magna eu laoreet. Nulla erat eros, ultricies in quam a, euismod finibus sem. Maecenas vulputate nunc et molestie malesuada.",
            last_message: "Blah blah blah",
            user_to: {
                name: 'Summer Films',
                username: 'summer'
            },
            user_from: {
                name: 'Michael Scott',
                username: 'michael_scott'
            },
            amount: 700000,
            files: [],
            steps: [
                {
                    label: 'Agree',
                    page: 'agree',
                    status: 'waiting',
                },
                {
                    label: 'Work',
                    page: 'work',
                    status: 'not_started'
                },
                {
                    label: 'Done',
                    page: 'done',
                    status: 'not_started'
                },
            ],
        },
        {
            label: "Fourth",
            message_read: false,
            starred: false,
            read: true,
            id: "1234",
            description: "",
            last_message: "Blah blah blah",
            user_to: {
                name: 'Summer Films',
                username: 'summer'
            },
            user_from: {
                name: 'Michael Scott',
                username: 'michael_scott'
            },
            amount: 200000,
            active_index: 1,
            files: [
                {
                    name: '1'
                },
                {
                    name: '2'
                },
                {
                    name: '3'
                }
            ],
            steps: [
                {
                    label: 'Agree',
                    page: 'agree',
                    status: 'complete',
                },
                {
                    label: 'Work',
                    page: 'work',
                    status: 'preparing'
                },
                {
                    label: 'Done',
                    page: 'done',
                    status: 'not_started'
                },
            ],
        },
        {
            label: "Fifth",
            message_read: true,
            starred: false,
            read: true,
            id: "1234",
            description: "",
            last_message: "Blah blah blah",
            user_to: {
                name: 'Summer Films',
                username: 'summer'
            },
            user_from: {
                name: 'Michael Scott',
                username: 'michael_scott'
            },
            amount: 200000,
            active_index: 2,
            files: [],
            steps: [
                {
                    label: 'Agree',
                    page: 'agree',
                    status: 'complete',
                },
                {
                    label: 'Work',
                    page: 'work',
                    status: 'complete'
                },
                {
                    label: 'Done',
                    page: 'done',
                    status: 'rejected'
                }
            ],
        },
        // {
        //     label: "Sixth",
        //     message_read: true,
        //     starred: false,
        //     read: true,
        //     id: "1234",
        //     description: "",
        //     last_message: "Blah blah blah",
        //     user_to: {
        //         name: 'Summer Films',
        //         username: 'summer'
        //     },
        //     user_from: {
        //         name: 'Michael Scott',
        //         username: 'michael_scott'
        //     },
        //     amount: 200000,
        //     active_index: 2,
        //     files: [
        //         {
        //             name: '1'
        //         },
        //         {
        //             name: '2'
        //         },
        //         {
        //             name: '3'
        //         }
        //     ],
        //     steps: [
        //         {
        //             label: 'Agree',
        //             page: 'agree',
        //             status: 'complete',
        //         },
        //         {
        //             label: 'Work',
        //             page: 'work',
        //             status: 'complete'
        //         },
        //         {
        //             label: 'Done',
        //             page: 'done',
        //             status: 'rejected'
        //         }
        //     ],
        // },
        // {
        //     label: "Seventh",
        //     message_read: true,
        //     starred: false,
        //     read: true,
        //     id: "1234",
        //     description: "",
        //     last_message: "Blah blah blah",
        //     user_to: {
        //         name: 'Summer Films',
        //         username: 'summer'
        //     },
        //     user_from: {
        //         name: 'Michael Scott',
        //         username: 'michael_scott'
        //     },
        //     amount: 200000,
        //     active_index: 2,
        //     files: [],
        //     steps: [
        //         {
        //             label: 'Agree',
        //             page: 'agree',
        //             status: 'complete',
        //         },
        //         {
        //             label: 'Work',
        //             page: 'work',
        //             status: 'complete'
        //         },
        //         {
        //             label: 'Done',
        //             page: 'done',
        //             status: 'complete'
        //         }
        //     ],
        // },
        // {
        //     label: "Eighth",
        //     message_read: true,
        //     starred: false,
        //     read: false,
        //     id: "1234",
        //     description: "",
        //     last_message: "",
        //     user_to: {
        //         name: 'Summer Films',
        //         username: 'summer'
        //     },
        //     user_from: {
        //         name: 'Michael Scott',
        //         username: 'michael_scott'
        //     },
        //     amount: 200000,
        //     active_index: 2,
        //     files: [],
        //     steps: [
        //         {
        //             label: 'Agree',
        //             page: 'agree',
        //             status: 'complete',
        //         },
        //         {
        //             label: 'Work',
        //             page: 'work',
        //             status: 'complete'
        //         },
        //         {
        //             label: 'Done',
        //             page: 'done',
        //             status: 'complete'
        //         }
        //     ],
        // },
        // {
        //     label: "Nineth",
        //     message_read: true,
        //     starred: false,
        //     read: false,
        //     id: "1234",
        //     description: "",
        //     last_message: "",
        //     user_to: {
        //         name: 'Summer Films',
        //         username: 'summer'
        //     },
        //     user_from: {
        //         name: 'Michael Scott',
        //         username: 'michael_scott'
        //     },
        //     amount: 200000,
        //     active_index: 2,
        //     files: [],
        //     steps: [
        //         {
        //             label: 'Agree',
        //             page: 'agree',
        //             status: 'complete',
        //         },
        //         {
        //             label: 'Work',
        //             page: 'work',
        //             status: 'complete'
        //         },
        //         {
        //             label: 'Done',
        //             page: 'done',
        //             status: 'complete'
        //         }
        //     ],
        // },
        // {
        //     label: "Tenth",
        //     message_read: true,
        //     starred: false,
        //     read: false,
        //     id: "1234",
        //     description: "",
        //     last_message: "",
        //     user_to: {
        //         name: 'Summer Films',
        //         username: 'summer'
        //     },
        //     user_from: {
        //         name: 'Michael Scott',
        //         username: 'michael_scott'
        //     },
        //     amount: 200000,
        //     active_index: 2,
        //     files: [],
        //     steps: [
        //         {
        //             label: 'Agree',
        //             page: 'agree',
        //             status: 'complete',
        //         },
        //         {
        //             label: 'Work',
        //             page: 'work',
        //             status: 'complete'
        //         },
        //         {
        //             label: 'Done',
        //             page: 'done',
        //             status: 'complete'
        //         }
        //     ],
        // },
        // {
        //     label: "Eleventh",
        //     message_read: true,
        //     starred: false,
        //     read: false,
        //     id: "1234",
        //     description: "",
        //     last_message: "",
        //     user_to: {
        //         name: 'Summer Films',
        //         username: 'summer'
        //     },
        //     user_from: {
        //         name: 'Michael Scott',
        //         username: 'michael_scott'
        //     },
        //     amount: 200000,
        //     active_index: 2,
        //     files: [],
        //     steps: [
        //         {
        //             label: 'Agree',
        //             page: 'agree',
        //             status: 'complete',
        //         },
        //         {
        //             label: 'Work',
        //             page: 'work',
        //             status: 'complete'
        //         },
        //         {
        //             label: 'Done',
        //             page: 'done',
        //             status: 'complete'
        //         }
        //     ],
        // },
        // {
        //     label: "Twelveth",
        //     message_read: true,
        //     starred: false,
        //     read: false,
        //     id: "1234",
        //     description: "",
        //     last_message: "",
        //     user_to: {
        //         name: 'Summer Films',
        //         username: 'summer'
        //     },
        //     user_from: {
        //         name: 'Michael Scott',
        //         username: 'michael_scott'
        //     },
        //     amount: 200000,
        //     active_index: 2,
        //     files: [],
        //     steps: [
        //         {
        //             label: 'Agree',
        //             page: 'agree',
        //             status: 'complete',
        //         },
        //         {
        //             label: 'Work',
        //             page: 'work',
        //             status: 'complete'
        //         },
        //         {
        //             label: 'Done',
        //             page: 'done',
        //             status: 'complete'
        //         }
        //     ],
        // },
        // {
        //     label: "Thirteenth",
        //     message_read: true,
        //     starred: false,
        //     read: false,
        //     id: "1234",
        //     description: "",
        //     last_message: "",
        //     user_to: {
        //         name: 'Summer Films',
        //         username: 'summer'
        //     },
        //     user_from: {
        //         name: 'Michael Scott',
        //         username: 'michael_scott'
        //     },
        //     amount: 200000,
        //     active_index: 2,
        //     files: [],
        //     steps: [
        //         {
        //             label: 'Agree',
        //             page: 'agree',
        //             status: 'complete',
        //         },
        //         {
        //             label: 'Work',
        //             page: 'work',
        //             status: 'complete'
        //         },
        //         {
        //             label: 'Done',
        //             page: 'done',
        //             status: 'complete'
        //         }
        //     ],
        // },
        // {
        //     label: "Fourteenth",
        //     message_read: true,
        //     starred: false,
        //     read: false,
        //     id: "1234",
        //     description: "",
        //     last_message: "",
        //     user_to: {
        //         name: 'Summer Films',
        //         username: 'summer'
        //     },
        //     user_from: {
        //         name: 'Michael Scott',
        //         username: 'michael_scott'
        //     },
        //     amount: 200000,
        //     active_index: 2,
        //     files: [],
        //     steps: [
        //         {
        //             label: 'Agree',
        //             page: 'agree',
        //             status: 'complete',
        //         },
        //         {
        //             label: 'Work',
        //             page: 'work',
        //             status: 'complete'
        //         },
        //         {
        //             label: 'Done',
        //             page: 'done',
        //             status: 'complete'
        //         }
        //     ],
        // },
    ]
}

const projectsCompleteReducer = (state = initialState, action) => {
    switch(action.type) {
        case ACTIONS.FETCH_PROJECTS:
            return [...action.payload.projects]
        case ACTIONS.TOGGLE_READ:
            return {
                ...state,
                expanded: action.payload.expanded
            }
        default:
            return state
    }
}

export default projectsCompleteReducer;