import React from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '../../theme/useTheme';

const FooterTab = (props) => {

    const {footer_tab} = useTheme()

    const styles = {
        container: {
            padding: '3px 10px',
            color: footer_tab.text,
            fontSize: '13px',
            fontWeight: '600',
            textDecoration: 'none',
            height: '45px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        activeStyle: {
            backgroundColor: '#192026',
            color: '#fff'
        }
    }

    return (
        <Link
            to={props.to}
            style={styles.container}
        >
            {props.label}
        </Link>
    );
};

export default FooterTab;