import React from 'react';
import { Route } from 'react-router-dom';
// import { CSSTransition } from 'react-transition-group';
import NotFound from '../components/utils/notFound/NotFound';
import ActivationEmail from '../components/landing/auth/ActivationEmail';
import {useSelector} from 'react-redux';
import Home from '../components/landing/Home';
import SignUpPage from '../components/landing/SignUpPage';
import SignInPage from '../components/landing/SignInPage';
import RequestAccess from '../components/landing/RequestAccess';
import ForgotPasswordPage from '../components/landing/ForgotPasswordPage';
import ResetPasswordPage from '../components/landing/ResetPasswordPage';
import JobsPage from '../components/landing/JobsPage';
// import ContentView from '../components/global/ContentView';

const LandingRoutes = () => {

    const token = useSelector(state => state.token)

    // const views = [
    //     {
    //         to: "/sign-in",
    //         component: (<SignInPage />)
    //     },
    //     {
    //         to: "/user/reset/:id",
    //         component: (isLogged ? <NotFound /> : <ResetPassword />)
    //     }
    // ]

    // const styles = {
    //     content: {
    //         position: 'absolute',
    //         top: '0',
    //         left: '0',
    //         right: '0',
    //         bottom: '0',
    //         overflow: 'auto'
    //     }
    // }

    return (
        <>
            {/* <div style={styles.content}> */}
                {/* <Route path="/" component={Home} /> */}
                {/* <Route path="/sign-up" component={SignUpPage} exact /> */}
                {/* <Route path="/user/activate/:activation_token" component={isLogged ? NotFound : ActivationEmail} exact /> */}

                <Route path="/" component={Home} exact />
                <Route path="/sign-up" component={SignUpPage} exact />
                <Route path="/log-in" component={SignInPage} exact />
                <Route path="/forgot-password" component={ForgotPasswordPage} exact />
                <Route path="/request-access" component={RequestAccess} exact />
                <Route path="/users/activate/:activation_token" component={token.length > 0 ? NotFound : ActivationEmail} exact />
                <Route path="/users/reset/:id" component={token.length > 0 ? NotFound : ResetPasswordPage} exact />

                <Route path="/mission" component={JobsPage} exact />

            {/* </div> */}

            {/* {
                views.map((view, index) => (
                    <Route 
                        key={view.to} 
                        path={view.to}
                    >
                        {({ match }) =>
                            <CSSTransition
                                key={view.to}
                                in={match != null}
                                timeout={2000}
                                classNames="slide-left"
                                unmountOnExit
                            >
                                <ContentView
                                    path="/"
                                >
                                    {view.component}
                                </ContentView>
                            </CSSTransition>
                        }
                    </Route>
                ))
            } */}
            
        </>
    );
};

export default LandingRoutes;
