import React from 'react';

const PageLoading = () => {

    const styles = {
        container: {
            height: '100vh',
            width: '100vw',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }
    }

    return (
        <div style={styles.container}>
            <div className="spinner"/>
        </div>
    );
};

export default PageLoading;