import React from 'react';
import Login from './auth/Login';
import AuthPage from './AuthPage';

const SignInPage = () => {
    return (
        <AuthPage>
            <Login />
        </AuthPage>
    );
};

export default SignInPage;