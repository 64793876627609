import ACTIONS from './index';
import axios from 'axios';

export const login = () => {
    return {
        type: ACTIONS.LOGIN
    }
}

export const loginWithData = (user) => {
    return {
        type: ACTIONS.LOGIN_WITH_DATA,
        payload: {user}
    }
}

export const logout = () => {
    return {
        type: ACTIONS.LOGOUT
    }
}

export const fetchUser = async(token) => {
    const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/user/infor`, {
        headers: {Authorization: token}
    });
    return res;
}

export const dispatchGetUser = (res) => {
    return {
        type: ACTIONS.GET_USER,
        payload: {
            user: res.data,
            isAdmin: res.data.role === 1
        }
    }
}

export const updateUserInfo = (first_name, last_name, avatar) => {
    return {
        type: ACTIONS.UPDATE_USER_INFO,
        payload: {
            first_name,
            last_name,
            avatar
        }
    }
}

export const updateAvatar = (url) => {
    return {
        type: ACTIONS.UPDATE_AVATAR,
        payload: {
            url
        }
    }
}

export const enableBeta = () => {
    return {
        type: ACTIONS.ENABLE_BETA
    }
}
