import ACTIONS from '../actions';

const initialState = {
    theme: 'NIGHT',
    auto: false
}

const themeReducer = (state = initialState, action) => {
    switch(action.type) {
        case ACTIONS.THEME:
            return {
                ...state,
                theme: action.payload
            }
        case ACTIONS.THEME_AUTO:
            return {
                ...state,
                auto: action.payload
            }
        default:
            return state
    }
}

export default themeReducer;