import ACTIONS from '../actions';

const initialState = {
    open: true,
    lists_visible: true,
    threads_visible: true,
    last_pages: []
}

const subSidebarReducer = (state = initialState, action) => {
    switch(action.type) {
        case ACTIONS.TOGGLE_SUBSIDEBAR:
            return {
                ...state,
                open: action.payload.open
            }
        case ACTIONS.TOGGLE_SUBSIDEBAR_HOVER:
            return {
                ...state,
                hovering: action.payload.hovering
            }
        case ACTIONS.TOGGLE_SUBSIDEBAR_LISTS:
            return [action.payload, ...state]
        case ACTIONS.TOGGLE_SUBSIDEBAR_THREADS:
            return [action.payload, ...state]
        default:
            return state
    }
}

export default subSidebarReducer;