import React, {useState} from 'react';
import { useTheme } from '../../../theme/useTheme';

const SubmitButton = (props) => {

    const [state, setState] = useState({
        hovering: false
    });
    const {landing} = useTheme()

    const styles = {
        button: {
            height: '60px',
            width: '100%',
            backgroundColor: landing.modal_btn.default,
            // backgroundColor: state.hovering ? landing.modal_btn.hover : landing.modal_btn.default,
            // color: state.hovering ? landing.submit.textHover : landing.submit.text,
            color: landing.submit.text,
            fontSize: '16px',
            fontWeight: '700',
            margin: '10px 0px',
            outline: 'none',
            cursor: 'pointer',
            WebkitAppearance: 'none',
            border: 'none',
            borderRadius: '6px'
        }
    }

    return (
            <button
                style={styles.button}
                onMouseEnter={() => setState({...state, hovering:true})}
                onMouseLeave={() => setState({...state, hovering:false})}
            >
                {props.children}
            </button>
    );
};

export default SubmitButton;