import ACTIONS from '../actions';

const initialState = ''

const filterReducer = (state = initialState, action) => {
    switch(action.type) {
        case ACTIONS.APPLY_FILTER:
            return action.payload
        default:
            return state
    }
}

export default filterReducer;