import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useTheme } from '../../theme/useTheme';
import AuthBackBtn from './AuthBackBtn';

const AuthPage = (props) => {

    const history = useHistory()

    useEffect(() => {
        const handleKeyDown = (e) => {
            if(e.key === 'Escape') {
                history.push("/")
            }
        }
        document.addEventListener('keydown', handleKeyDown)
        return () => {
            document.removeEventListener('keydown', handleKeyDown)
        }
    }, [])

    const {landing} = useTheme()

    const styles = {
        container: {
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: landing.hero.background
        }
    }

    return (
        <div style={styles.container}>
            <AuthBackBtn />
            {props.children}
        </div>
    );
};

export default AuthPage;