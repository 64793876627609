import ACTIONS from '../actions';

const initialState = []

const tabsReducer = (state = initialState, action) => {
    switch(action.type) {
        case ACTIONS.NEW_TAB:
            return [
                ...state,
                action.payload
            ]
        case ACTIONS.CLOSE_TAB:
            return state.filter((item) => {
                if (item.project_id !== action.payload.project_id) return item
            })
        default:
            return state
    }
}

export default tabsReducer;