import React from 'react';
import Register from './auth/Register';
import AuthHeader from './AuthHeader';

const RequestAccess = () => {

    const styles = {
        container: {
            paddingTop: '50px'
        }
    }

    return (
        <div
            style={styles.container}
        >
            <AuthHeader
                title="Request Access"
            />

            ...
        </div>
    );
};

export default RequestAccess;