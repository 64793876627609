import { combineReducers } from 'redux';
import auth from './authReducer';
import token from './tokenReducer';
import theme from './themeReducer';
import sidebar from './sidebarReducer';
import page from './pageReducer';
import projectsStarred from './projectsStarredReducer';
import projectsIncomplete from './projectsIncompleteReducer';
import projectsComplete from './projectsCompleteReducer';
import projectsInvited from './projectsInvitedReducer';
import paragraphs from './paragraphsReducer';
import currentProject from './currentProjectReducer';
import history from './historyReducer';
import search from './searchReducer';
import projectMeta from './projectMetaReducer';
import rects from './rectsReducer';
import keyLog from './keyLogReducer';
import teams from './teamsReducer';
import lists from './listsReducer';
import list from './listReducer';
import subSidebar from './subSidebarReducer';
import tabs from './tabsReducer';
import lastList from './lastListReducer';
import header from './headerReducer';
import contentPage from './contentPageReducer';
import comments from './commentReducer';
import mobile from './mobileReducer';
import modal from './modalReducer';
import gesture from './gestureReducer';
import tribe from './tribeReducer';
import tribes from './tribesReducer';
import mission from './missionReducer';
import missions from './missionsReducer';
import inbox from './inboxReducer';
import milestone from './milestoneReducer';
import filter from './filterReducer';
import project from './projectReducer';
import chat from './chatReducer';

export default combineReducers({
    auth,
    token,
    theme,
    sidebar,
    page,
    projectsStarred,
    projectsIncomplete,
    projectsComplete,
    projectsInvited,
    paragraphs,
    currentProject,
    history,
    search,
    projectMeta,
    rects,
    keyLog,
    teams,
    lists,
    subSidebar,
    list,
    tabs,
    lastList,
    header,
    contentPage,
    comments,
    mobile,
    modal,
    gesture,
    tribe,
    tribes,
    mission,
    missions,
    inbox,
    milestone,
    filter,
    project,
    chat
});