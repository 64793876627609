import ACTIONS from '../actions';

const initialState = {
    title: "My First Gig",
    id: "1234",
    starred: true,
    role: 'employee',
    direction: 'pay',
    type: 'milestone',
    steps: [
        {
            label: 'Agree',
            page: 'agree',
            status: 'complete',
        },
        {
            label: 'Work',
            page: 'work',
            status: 'preparing',
        },
        {
            label: 'Done',
            page: 'done',
            status: 'not_started',
        },
    ]
}

const projectReducer = (state = initialState, action) => {
    switch(action.type) {
        case ACTIONS.LOAD_PROJECT:
            return [
                ...state,
                action.payload
            ]
        default:
            return state
    }
}

export default projectReducer;