import React from 'react';
import axios from 'axios';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {logout} from '../../../redux/actions/authAction';
import { useTheme } from '../../../theme/useTheme';

const Logout = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const {logout_btn} = useTheme()

    const handleLogout = async(e) => {
        e.preventDefault()
        try {
            await axios.get(`${process.env.REACT_APP_API_BASE_URL}/users/logout`);
            dispatch(logout());
            dispatch({type: 'CLEAR_TOKEN'});
            localStorage.removeItem('firstLogin');
            history.push('/');
        } catch (error) {
            console.log({error})
            history.push('/');
        }
    }

    const styles = {
        container: {
            height: '70px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: logout_btn.text,
            fontSize: '16px',
            fontWeight: '700'
        }
    }

    return (
        <a
            href="/"
            style={styles.container}
            onClick={(e) => handleLogout(e)}
        >
            Log Out
        </a>
    );
};

export default Logout;
