import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '../../theme/useTheme';

const BigLogo = (props) => {

    const {landing} = useTheme()

    const [state, setState] = useState({
        hovering: false
    })

    const styles = {
        container: {
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            marginTop: '-100px',
        },
        link: {
            textDecoration: 'none',
            color: landing.logo,
            fontSize: '80px',
            fontWeight: '900',
        },
        label: {
            fontSize: '18px',
            fontWeight: '400',
            color: landing.subtext,
            marginTop: '-10px'
        }
    }

    return (
        <div
            style={styles.container}
        >
            <div
                style={styles.link}
                // onMouseEnter={() => setState({...state, hovering: true})}
                // onMouseLeave={() => setState({...state, hovering: false})}
            >
                Gig

            </div>

            <span style={styles.label}>
                Work smarter.
            </span>
        </div>
    );
};

export default BigLogo;