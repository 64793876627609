import React from 'react';
import Register from './auth/Register';
import AuthPage from './AuthPage';

const SignUpPage = () => {
    return (
        <AuthPage>
            <Register />
        </AuthPage>
    );
};

export default SignUpPage;