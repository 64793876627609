import ACTIONS from '../actions';

const initialState = []

const teamsReducer = (state = initialState, action) => {
    switch(action.type) {
        case ACTIONS.CREATE_TEAM:
            return [action.payload, ...state]
        default:
            return state
    }
}

export default teamsReducer;