import ACTIONS from '../actions';

const header = {
    title: '',
    component: '',
    footer: false,
    backBtn: false
}

const headerReducer = (state = header, action) => {
    switch(action.type) {
        case ACTIONS.SET_HEADER:
            return action.payload
        case ACTIONS.CLEAR_HEADER:
            return header
        default:
            return state
    }
}

export default headerReducer;