import ACTIONS from '../actions';

const initialState = {
    user: {},
    users: [],
    isLogged: false,
    isAdmin: false
}

const authReducer = (state = initialState, action) => {
    switch(action.type) {
        case ACTIONS.LOGIN:
            return {
                ...state,
                isLogged: true
            }
        case ACTIONS.LOGIN_WITH_DATA:
            return {
                ...state,
                isLogged: true,
                user: action.payload.user,
                users: [
                    action.payload.user,
                    ...state.users.filter((user) => user.user_id !== action.payload.user.user_id)
                ]
            }
        case ACTIONS.LOGOUT:
            return {
                ...state,
                isLogged: false,
                user: {}
            }
        case ACTIONS.GET_USER:
            return {
                ...state,
                user: action.payload.user,
                isAdmin: action.payload.isAdmin
            }
        case ACTIONS.ENABLE_BETA:
            return {
                ...state,
                user: {
                    ...state.user,
                    isBeta: true
                }
            }
        case ACTIONS.UPDATE_AVATAR:
            return {
                ...state,
                user: {
                    ...state.user,
                    avatar: action.payload.url
                }
            }
        case ACTIONS.UPDATE_USER_INFO:
            return {
                ...state,
                user: {
                    ...state.user,
                    first_name: action.payload.first_name,
                    last_name: action.payload.last_name,
                    avatar: action.payload.avatar
                }
            }
        default:
            return state
    }
}

export default authReducer;