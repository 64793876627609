import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '../../theme/useTheme';

const BigButtonSolid = (props) => {

    const {big_button} = useTheme()

    const [state, setState] = useState({
        hovering: false
    })

    const styles = {
        container: {
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: big_button.solid.background.default,
            color: big_button.solid.text.default,
            width: '100%',
            height: '60px',
            fontSize: '16px',
            fontWeight: '700',
            marginTop: '30px',
            textDecoration: 'none',
            borderRadius: '6px'
        },
    }

    return (
        <Link
            to={props.to}
            style={styles.container}
            onMouseEnter={() => setState({...state, hovering: true})}
            onMouseLeave={() => setState({...state, hovering: false})}
        >
            {props.label}
        </Link>
    );
};

export default BigButtonSolid;