import ACTIONS from './index';

export const setTheme = (newTheme = 'DAY') => {
    return {
        type: ACTIONS.THEME,
        payload: newTheme
    }
}

export const setAuto = (newAuto = false) => {
    return {
        type: ACTIONS.THEME_AUTO,
        payload: newAuto
    }
}
