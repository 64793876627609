import React from 'react';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import {Provider} from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import rootReducer from '../redux/reducers';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
    key: 'root',
    storage: storage,
    whitelist: ['auth', 'theme', 'mobile', 'chat']
}

const initialState = {};

const middleware = [thunk];

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = 
    process.env.REACT_APP_NODE_ENV === 'development' ? 
    createStore(
        persistedReducer, 
        initialState, 
        compose(
            applyMiddleware(...middleware),
            window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
        )
    ) : 
    createStore(
        persistedReducer, 
        initialState, 
        compose(
            applyMiddleware(...middleware)
        )
    )

// const store = createStore(
//     persistedReducer, 
//     initialState, 
//     compose(
//         applyMiddleware(...middleware)
//     )
// )

const persistor = persistStore(store);

const DataProvider = ({children}) => {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                {children}
            </PersistGate>
        </Provider>
    );
};

export default DataProvider;