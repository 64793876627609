import ACTIONS from '../actions';

const initialState = {
    open: false,
    hovering: false,
    project_open: false,
    show_tribes: true
}

const sidebarReducer = (state = initialState, action) => {
    switch(action.type) {
        case ACTIONS.SIDEBAR_OPEN:
            return {
                ...state,
                open: action.payload
            }
        case ACTIONS.SIDEBAR_HOVER:
            return {
                ...state,
                hovering: action.payload
            }
        case ACTIONS.SIDEBAR_PROJECT_OPEN:
            return {
                ...state,
                project_open: action.payload
            }
        case ACTIONS.SIDEBAR_SHOW_TRIBES:
            return {
                ...state,
                show_tribes: action.payload
            }
        default:
            return state
    }
}

export default sidebarReducer;