import React from 'react';

const ModalTitle = (props) => {

    const styles = {
        container: {
            width: '100%',
            height: '60px',
            fontSize: '30px',
            fontWeight: '900',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }
    }

    return (
        <div style={styles.container}>
            {props.title}
        </div>
    );
};

export default ModalTitle;