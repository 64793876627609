import {palette} from '../palette';

let header_bg = palette.grey.nine_hundred.dark
let tab_bg = palette.grey.nine_hundred.primary
let tab_bg_active = palette.indigo.seven_hundred

let primary = '#0179FF'
// let primary = '#156EB0'

export const night = {
    activation: {
        text: '#fff',
        background: '#000'
    },
    landing: {
        text: 'rgba(255, 255, 255, 1)',
        header: {
            background: '#1D1F21',
            text: palette.grey.fifty.light,
            link: '#635F54',
            back_button: {
                background: {
                    default: 'rgba(18, 18, 18, 1)',
                    hover: 'rgba(58, 58, 58, 1)',
                },
                icon: palette.grey.fifty.light,
                esc: 'rgba(200, 200, 200, 1)'
            }
        },
        hero: {
            background: 'rgba(0, 0, 0, 1)',
            title: palette.grey.fifty.light,
            description: palette.grey.six_hundred.primary,
            section: palette.blue_grey.fifty.primary
        },
        beta_btn: {
            background: {
                default: palette.grey.fifty.light
            },
            text: '#1D1F21'
        },
        features: {
            background: palette.blue_grey.fifty.primary,
        },
        footer: {
            background: palette.grey.fifty.light,
            text: palette.grey.nine_hundred.dark,
            divider: palette.grey.six_hundred.primary,
        },
        modal: {
            backdrop: 'rgba(255, 255, 255, 0.9)',
        },
        modal_btn: {
            default: '#fff',
            hover: palette.grey.eight_hundred.primary,
            text: palette.grey.six_hundred.primary,
            text_hover: palette.grey.eight_hundred.primary,
            shadow: 'rgba(0, 0, 0, 0.4)',
            shadowHover: 'rgba(0, 0, 0, 0.6)',
            border: palette.grey.six_hundred.primary,
            border_hover: palette.grey.eight_hundred.primary
        },
        submit: {
            default: palette.blue.five_hundred.primary,
            hover: palette.blue.six_hundred.primary,
            text: '#000',
            textHover: palette.grey.fifty.light,
        },
        logo: {
            text: palette.grey.nine_hundred.dark,
            version: {
                default: palette.blue_grey.two_hundred.primary,
                text: palette.grey.eight_hundred.primary,
            }
        }
    },
    text_input: {
        background: {
            default: '#000',
            active: palette.grey.fifty.primary,
        },
        color: '#fff',
        border: {
            default: 'rgba(58, 58, 58, 1)',
            active: 'rgba(255, 255, 255, 1)',
        }
    },
    contract_preview: {
        background: {
            default: '#000',
            hover: '#192026'
        },
        badge: {
            background: '#B7BBBE',
            icon: '#000'
        },
        expand: '#B8B8B8',
        title: '#B7BBBE',
        subtitle: '#B7BBBE',
    },
    project: {
        // background: palette.grey.eight_hundred.dark,
        background: '#000',
        border: '#3B464C',
        background_gradient: {
            top: palette.grey.nine_hundred.primary,
            bottom: palette.grey.eight_hundred.dark,
        }
    },
    // project_card: {
    //     background: '#363739'
    // },
    steps: {
        background: palette.grey.eight_hundred.primary,
        shadow: {
            default: palette.grey.two_hundred.primary,
            hover: palette.grey.three_hundred.primary,
        }
    },
    step: {
        label: {
            default: palette.grey.nine_hundred.primary
        },
        background: {
            default: palette.grey.fifty.light,
            hover: palette.grey.fifty.primary
        },
        shadow: {
            default: palette.grey.three_hundred.primary,
            hover: palette.grey.three_hundred.primary,
        },
        label: {
            blue: palette.blue.four_hundred.primary,
            green: palette.green.four_hundred.primary,
            amber: palette.amber.four_hundred
        }
    },
    direct_message: {
        border: palette.grey.nine_hundred.primary,
        backdrop: palette.grey.nine_hundred.dark,
        background: {
            default: palette.blue_grey.nine_hundred.dark,
            hover: palette.blue_grey.eight_hundred.dark,
            active: palette.blue_grey.seven_hundred.dark,
        }
    },
    add_tribe_page: {
        background: palette.grey.eight_hundred.dark
    },
    overlay: {
        background: 'rgba(58, 58, 58, 1)',
        text: palette.grey.nine_hundred.dark,
        backdrop: 'rgba(0, 0, 0, 0.75)',
        // shadow: '0px 1px 40px 0 rgb(9 10 12 / 30%), 0px 1px 2px 0 rgb(9 10 12 / 50%)',
        shadow: 'rgb(15 15 15 / 10%) 0px 0px 0px 1px, rgb(15 15 15 / 20%) 0px 3px 6px, rgb(15 15 15 / 40%) 0px 9px 24px'
    },
    message: {
        background: {
            default: '#192026',
            hover: palette.grey.two_hundred.primary,
        },
        time: palette.grey.six_hundred.primary
    },
    main_footer: {
        background: palette.grey.nine_hundred.dark,
        text: palette.grey.fifty.light,
        border: palette.blue_grey.seven_hundred.dark,
        tab: {
            background: {
                default: palette.grey.nine_hundred.dark,
                active: palette.grey.nine_hundred.dark,
            },
            icon: {
                default: palette.grey.six_hundred.primary,
                active: palette.green.five_hundred.primary,
            }
        }
    },
    right_now: {
        background: palette.grey.nine_hundred.dark
    },
    up_next: {
        header: {
            border: palette.blue_grey.one_hundred.primary,
            background: palette.blue_grey.two_hundred.primary,
            text: palette.grey.fifty.light,
        },
        backdrop: palette.blue_grey.three_hundred.primary,
        row: {
            background: {
                default: palette.blue_grey.two_hundred.primary,
                active: palette.grey.eight_hundred.primary
            },
            text: {
                default: palette.grey.fifty.light
            }
        }
        
    },
    mission_about: {
        background: palette.grey.nine_hundred.dark
    },
    // tool_item: {
    //     background: {
    //         default: palette.grey.nine_hundred.primary,
    //         active: palette.blue_grey.seven_hundred.dark,
    //         hover: palette.grey.nine_hundred.primary
    //     },
    //     text: {
    //         unread: palette.grey.one_hundred.primary,
    //         default: palette.grey.five_hundred.primary,
    //         active: palette.grey.fifty.light,
    //         hover: palette.grey.fifty.light,
    //     },
    //     unread: palette.blue.six_hundred.primary
    // },
    inbox_page: {
        body: {
            background: 'rgba(18, 18, 18, 1)',
            shadow: '',
        },
        header: {
            background: palette.grey.fifty.light,
            border: palette.blue_grey.one_hundred.primary,
        },
        content: {
            background: palette.grey.fifty.light
        },
        add_thread: {
            background: {
                default: palette.blue_grey.fifty.primary,
                hover: palette.blue_grey.two_hundred.primary,
            },
            text: {
                default: palette.blue_grey.six_hundred.primary,
                hover: palette.blue_grey.nine_hundred.primary,
            },
            button: {
                background: {
                    default: palette.blue_grey.one_hundred.primary,
                    hover: palette.blue_grey.three_hundred.primary,
                },
                icon: {
                    default: palette.blue_grey.six_hundred.primary,
                    hover: palette.blue_grey.nine_hundred.primary,
                }
            }
        },
        search: {
            icon: palette.grey.fifty.light,
            text: palette.grey.fifty.light,
            border: palette.blue_grey.one_hundred.primary,
            background: {
                default: palette.blue_grey.fifty.primary,
                active: palette.grey.seven_hundred.dark
            }
        },
        sidebar: {
            background: palette.grey.fifty.light,
            border: palette.grey.three_hundred.primary,
            section: {
                background: palette.grey.fifty.light,
                text: {
                    default: palette.blue_grey.six_hundred.primary,
                    hover: palette.blue_grey.four_hundred.primary
                }
            },
            row: {
                background: {
                    default: palette.grey.fifty.light,
                    active: palette.blue_grey.two_hundred.primary,
                    hover: palette.blue_grey.two_hundred.primary
                },
                text: {
                    unread: palette.blue_grey.nine_hundred.primary,
                    default: palette.blue_grey.six_hundred.primary,
                    active: palette.blue_grey.nine_hundred.primary,
                    hover: palette.blue_grey.eight_hundred.primary,
                },
                unread: palette.blue.six_hundred.primary
            }
        },
        footer: {
            background: '#0F151B',
            spacer: palette.grey.five_hundred.primary,
            input: {
                background: {
                    default: '#000',
                },
                border: {
                    default: palette.grey.seven_hundred.primary,
                    active: palette.grey.six_hundred.primary,
                },
                text: palette.grey.nine_hundred.dark
            },
            submit_btn: {
                background: {
                    default: palette.grey.eight_hundred.dark,
                    hover: palette.grey.seven_hundred.dark,
                    active: palette.grey.seven_hundred.dark
                },
                icon: {
                    default: '#4030FF',
                    hover: palette.grey.fifty.light,
                    active: palette.grey.eight_hundred.dark
                }
            },
            file_btn: {
                background: {
                    default: palette.grey.nine_hundred.dark,
                    hover: palette.blue_grey.eight_hundred.dark,
                    active: palette.blue_grey.eight_hundred.dark
                },
                icon: {
                    default: palette.grey.seven_hundred.primary,
                    hover: palette.grey.six_hundred.primary,
                    active: palette.grey.eight_hundred.dark
                }
            },
            message: palette.blue_grey.seven_hundred.primary
        }
    },
    timeline: {
        line: {
            default: palette.blue_grey.seven_hundred.primary
        }
    },
    shadow: '0 0 0 1px rgb(136 152 170 / 10%), 0 15px 35px 0 rgb(49 49 93 / 10%), 0 5px 15px 0 rgb(0 0 0 / 8%)',
    textInput: {
        default: palette.grey.eight_hundred.dark,
        hover: palette.grey.eight_hundred.primary,
        active: palette.grey.eight_hundred.primary,
        border: palette.grey.eight_hundred.primary,
        label: palette.grey.three_hundred.primary,
        text: palette.grey.nine_hundred.light,
        textFocused: palette.grey.nine_hundred.dark,
        optionActive: palette.blue.four_hundred.primary,
        optionActiveText: palette.grey.fifty.light,
        shadow: 'rgba(0, 0, 0, 0.15)'
    },
    form_items: {
        footer_border: palette.grey.three_hundred.primary,
        footer_shadow: 'rgba(0, 0, 0, 0.25)',
        footer_background: palette.grey.fifty.light,
        result_default: palette.grey.fifty.light,
        result_hover: palette.grey.one_hundred.primary,
    },
    post: {
        background: {
            default: palette.blue_grey.nine_hundred.dark,
            hover: palette.blue_grey.eight_hundred.dark,
        },
        border: {
            default: palette.grey.nine_hundred.primary,
            hover: palette.grey.eight_hundred.primary
        },
        text: palette.grey.fifty.light
    },
    project_picker: {
        default: palette.grey.nine_hundred.dark,
        header_active: palette.grey.nine_hundred.primary,
        border: palette.grey.eight_hundred.primary,
        shadow: 'rgba(0, 0, 0, 0.15)',
        background: palette.grey.fifty.light,
        icon: palette.grey.six_hundred.primary,
        label: palette.grey.three_hundred.primary,
        row: {
            background: {
                default: palette.grey.fifty.light,
                active: palette.grey.three_hundred.primary,
                hover: palette.grey.two_hundred.primary,
            },
            text: {
                default: palette.grey.nine_hundred.dark,
                active: palette.grey.nine_hundred.dark,
            }
        },
        text: {
            empty: palette.grey.six_hundred.primary,
            active: palette.grey.fifty.light,
        },
        new_button: {
            text: {
                default: palette.grey.fifty.light
            },
            background: {
                default: palette.grey.nine_hundred.dark
            }
        }
    },
    form: {
        background: palette.grey.fifty.light,
        add_user: {
            text: {
                default: palette.grey.nine_hundred.dark,
                open: palette.grey.fifty.light
            },
            background: {
                open: palette.grey.nine_hundred.dark,
                hover: palette.grey.one_hundred.primary,
                default: palette.grey.fifty.light
            },
            checkbox: palette.grey.nine_hundred.dark,
            label: palette.grey.six_hundred.primary,
            lookup_icon: palette.grey.six_hundred.primary,
            dropdown: {
                background: palette.grey.fifty.light,
                highlight: palette.blue.fifty.primary
            }
        },
        tab: {
            border: {
                active: palette.grey.nine_hundred.dark,
                default: palette.grey.three_hundred.primary
            },
            text: {
                active: palette.grey.nine_hundred.dark,
                default: palette.grey.seven_hundred.primary
            }
        }
    },
    big_modal: {
        bg: 'rgba(0, 10, 18, 0.8)',
        shadow: 'rgba(0, 0, 0, 0.25)',
        border: palette.blue_grey.fifty.primary,
        panel: palette.blue_grey.twenty_five.primary,
        tab_text: palette.blue_grey.six_hundred.primary
    },
    global: {
        background: palette.grey.eight_hundred.dark,
    },
    project_nav: {
        default: palette.grey.fifty.light,
        text: palette.grey.eight_hundred.primary,
        hover: palette.grey.two_hundred.primary,
        active: palette.blue.one_hundred.primary,
        textActive: palette.blue.seven_hundred.primary,
    },
    nav_button: {
        default: palette.grey.fifty.primary,
        hover: palette.grey.fifty.dark,
        active: palette.teal.one_hundred.primary,
        textActive: palette.teal.five_hundred.dark,
        text: palette.grey.six_hundred.primary
    },
    media_thumb: {
        background: {
            default: '#121317'
        }
    },
    step_nav: {
        background: {
            default: '#E8E7E4',
            hover: '#BDBCB8',
            disabled: '#E8E7E4'
        },
        icon: {
            default: '#37352F',
            hover: '#37352F',
            disabled: '#E8E7E4'
        }
    },
    project_content: {
        background: 'rgba(18, 18, 18, 1)',
        content: {
            background: 'rgba(50, 50, 50, 1)'
        },
        border: `rgba(40, 40, 40, 1)`,
        // shadow: `0 0 6px rgba(10, 10, 10, 0.75)`,
        text: '#fff',
        header: {
            background: 'rgba(0, 0, 0, 1)',
        }
    },
    file_search: {
        border: {
            // default: `0 0 0 1px #282828, 0 0 0 2px rgba(10, 10, 10, 0.5), 0 0 6px rgba(10, 10, 10, 0.75)`
            default: 'rgba(43, 43, 43, 1)',
        },
        background: {
            default: 'rgba(28, 28, 28, 0.7)',
            active: '#262A2C',
        },
        icon: {
            active: 'rgba(0, 0, 0, 1)',
            default: 'rgba(255, 255, 255, 1)',
        },
        icon_mobile: {
            active: 'rgba(0, 0, 0, 1)',
            default: 'rgba(120, 120, 120, 0.8)',
        },
        text: palette.grey.fifty.light
    },
    search_bar: {
        default: palette.grey.one_hundred.primary,
        active: palette.grey.fifty.light,
        activeBorder: palette.grey.fifty.dark,
        icon: palette.grey.six_hundred.primary
    },
    sidebar: {
        hover_indicator: {
            background: palette.blue_grey.three_hundred.primary
        },
        add_tribe: {
            background: {
                default: palette.blue_grey.six_hundred.primary,
                hover: palette.blue_grey.two_hundred.primary,
            },
            text: {
                default: palette.blue_grey.four_hundred.primary,
                hover: palette.blue_grey.nine_hundred.primary,
            },
            button: {
                background: {
                    default: palette.blue_grey.five_hundred.primary,
                    hover: palette.blue_grey.three_hundred.primary,
                },
                icon: {
                    default: palette.blue_grey.two_hundred.primary,
                    hover: palette.blue_grey.nine_hundred.primary,
                }
            }
        },
    },
    subsidebar: {
        default: palette.grey.eight_hundred.dark,
        border: palette.blue_grey.twenty_five.primary,
        text: palette.grey.nine_hundred.dark,
        item: {
            default: palette.grey.fifty.light,
            border: palette.blue_grey.fifty.primary,
            active: palette.blue_grey.one_hundred.primary,
            active_text: palette.grey.nine_hundred.dark,
            hover: palette.blue_grey.twenty_five.primary,
            icon: palette.green.five_hundred.primary
        },
        search: {
            default: palette.blue_grey.twenty_five.primary,
            icon: palette.blue_grey.five_hundred.primary
        },
        header: {
            default: palette.grey.fifty.light,
            hover: palette.blue_grey.twenty_five.primary,
            icon: palette.blue_grey.five_hundred.primary
        }
    },
    logo: {
        text: palette.grey.fifty.light,
        beta: {
            default: palette.blue_grey.two_hundred.primary,
            text: palette.blue_grey.eight_hundred.primary,
        }
    },
    // sidebar_pin: {
    //     default: palette.grey.fifty.light,
    //     icon: palette.grey.four_hundred.primary,
    //     shadow: 'rgba(0, 0, 0, 0.15)',
    //     border: palette.grey.three_hundred.primary
    // },
    // new_btn: {
    //     default: palette.grey.nine_hundred.primary,
    //     item: palette.grey.seven_hundred.primary,
    //     hover: palette.grey.fifty.dark,
    //     active: palette.grey.eight_hundred.primary,
    //     textActive: palette.grey.fifty.light,
    //     text: palette.grey.one_hundred.primary,
    //     accent: palette.blue.five_hundred.primary,
    //     border: palette.blue.four_hundred.dark
    // },
    // poster: {
    //     default: palette.blue_grey.fifty.primary,
    //     hover: palette.grey.one_hundred.primary,
    //     border: palette.blue_grey.one_hundred.primary,
    //     backdrop_hover: palette.grey.fifty.light,
    //     icon: palette.blue_grey.two_hundred.primary,
    //     text: palette.blue_grey.nine_hundred.primary
    // },
    // project_thumb: {
    //     default: palette.grey.seven_hundred.primary,
    //     text: palette.grey.nine_hundred.primary,
    //     title: palette.grey.fifty.light,
    //     title_empty: palette.grey.five_hundred.primary,
    //     hover: palette.grey.one_hundred.primary,
    //     selected: palette.grey.two_hundred.primary,
    //     selectedText: palette.grey.fifty.light,
    //     selectedHighlight: palette.blue.seven_hundred.primary,
    //     border: palette.grey.three_hundred.primary,
    // },
    // big_title: {
    //     title: palette.grey.fifty.light,
    //     subtitle: palette.grey.five_hundred.primary,
    // },
    // badge: {
    //     background: palette.grey.nine_hundred.primary,
    //     background_light: palette.grey.eight_hundred.primary,
    //     item: palette.grey.eight_hundred.primary,
    //     hover: palette.grey.seven_hundred.primary,
    //     text: palette.grey.one_hundred.primary,
    //     icon: palette.grey.one_hundred.primary,
    //     label: palette.grey.one_hundred.primary,
    //     border: palette.grey.eight_hundred.dark,
    //     active: 'rgba(25, 118, 210, 0.25)',
    //     textActive: palette.blue.six_hundred.primary,
    //     shadow: 'rgba(0, 0, 0, 0)',
    //     shadowHover: 'rgba(0, 0, 0, 0.4)',

    //     star: palette.grey.six_hundred.primary,
    //     starActive: palette.yellow.eight_hundred,
    // },
    user_link: {
        default: palette.grey.six_hundred.primary,
        hover: palette.teal.five_hundred
    },
    // project_bar: {
    //     default: palette.grey.three_hundred.primary,
    //     hover: palette.grey.fifty.dark,
    //     active: palette.teal.one_hundred.primary,
    //     textActive: palette.teal.five_hundred.dark,
    //     text: palette.grey.six_hundred.primary,
    //     header: palette.grey.eight_hundred.dark,
    //     subheader: palette.grey.eight_hundred.dark,
    //     border: palette.grey.nine_hundred.primary,
    //     shadow: 'rgba(0, 0, 0, 0.7)'
    // },
    // minibar: {
    //     background: palette.grey.fifty.light,
    //     border: palette.grey.one_hundred.primary,
    //     logo: palette.grey.nine_hundred.dark,
    //     item: {
    //         background: {
    //             default: palette.grey.fifty.light,
    //             hover: palette.grey.one_hundred.primary,
    //             active: palette.grey.nine_hundred.dark,
    //         },
    //         icon: {
    //             default: palette.grey.five_hundred.primary,
    //             active: palette.grey.fifty.light,
    //         }
    //     },
    //     footer: {
    //         background: palette.grey.fifty.light
    //     }
    // },
    // milestone_toolbar: {
    //     border: '#353A45'
    // },
    // read_status: {
    //     default: primary
    // },
    toggle_menu: {
        main: {
            background: {
                default: 'rgba(255, 255, 255, 1)',
                // default: 'rgba(54, 121, 255, 0.9)',
                hover: '#5C92FF',
                active: 'purple',
            },
            border: {
                default: 'rgba(255, 255, 255, 1)',
                hover: 'rgba(0, 0, 0, 1)',
                active: 'purple',
            },
            text: {
                default: 'rgba(0, 0, 0, 1)',
                hover: '#fff',
                active: '#fff',
            },
            shadow: {
                default: 'rgba(54, 121, 255, 0.7) 0px 0px 0px 2px, rgba(54, 121, 255, 0.3) 0px 0px 0px 4px, #262A2C 0px 0px 20px 0px',
                hover: 'rgba(54, 121, 255, 0.6) 0px 0px 0px 3px',
            }
        },
        arrow_container: {
            background: {
                default: '#090A0C',
                hover: '#090A0C',
                active: '#090A0C',
            },
            text: {
                default: '#B8B8B8',
                hover: '#fff',
                active: '#fff',
            }
        },
        dropdown: {
            background: '#3F4447',
            shadow: 'rgb(15 15 15 / 10%) 0px 0px 0px 1px, rgb(15 15 15 / 20%) 0px 3px 6px, rgb(15 15 15 / 40%) 0px 9px 24px'
        }
    },
    back_btn: {
        background: {
            default: '#000',
            hover: '#363739',
        },
        text: {
            default: '#B8B8B8',
            hover: '#fff',
        }
    },
    group_toggle: {
        background: {
            default: 'rgba(0, 0, 0, 1)',
            // default: 'rgba(18, 18, 18, 1)',
            hover: 'rgba(18, 18, 18, 1)',
        },
        text: {
            default: 'rgba(160, 160, 160, 1)',
            hover: '#fff',
        }
    },
    star: {
        default: {
            default: 'rgba(120, 120, 120, 1)',
            hover: 'rgba(150, 150, 150, 1)'
        },
        active: {
            default: 'rgba(200, 200, 200, 1)',
            hover: 'rgba(200, 200, 200, 1)'
        }
    },
    more_button: {
        background: {
            hover: 'tan'
        },
        icon: {
            default: '#B8B8B8',
            hover: 'red',
        }
    },
    project_card: {
        background: {
            unread: {
                // default: 'rgba(0, 0, 0, 1)',
                default: 'rgba(58, 58, 58, 1)',
                hovering: 'rgba(68, 68, 68, 1)',
            },
            read: {
                default: 'rgba(18, 18, 18, 1)',
                hovering: 'rgba(28, 28, 28, 1)',
            },
            invitation: {
                default: 'rgba(1, 121, 255, 0.6)',
                hovering: 'rgba(28, 28, 28, 1)',
            }
        },
        text: palette.grey.fifty.light,
        border: {
            default: '#000',
            hover: palette.grey.four_hundred.primary
        },
        shadow: {
            default: `0 0 0 1px rgba(12, 12, 12, 1), 0 0 2px 2px rgba(10, 10, 10, 1)`,
            hover: palette.grey.nine_hundred.primary
        },
        label: {
            read: 'rgba(120, 120, 120, 1)',
            unread: '#FFFFFF'
        }
    },
    send_message_btn: {
        background: {
            default: '#454E9E',
            hover: '#2B3068'
        }
    },
    bucket: {
        background: palette.grey.eight_hundred.dark
    },
    quick_project_form: {
        background: palette.grey.nine_hundred.dark,
        title: palette.grey.fifty.light,
        add_button: {
            background: {
                default: '#131516',
                hover: '#1C1F21',
            },
            text: {
                hover: '#fff',
                default: primary,
            },
        },
        button: {
            background: {
                default: primary,
                hover: '#1C1F21',
            },
            text: {
                hover: '#000',
                default: palette.grey.fifty.light,
            },
            border: {
                hover: '#000',
                default: palette.green.six_hundred,
            }
        },
        text_button: {
            text: {
                hover: palette.grey.four_hundred.primary,
                default: palette.grey.six_hundred.primary,
            }
        },
        shadow: {
            hover: palette.grey.nine_hundred.primary,
            default: palette.grey.eight_hundred.dark
        }
    },
    theme_toggle: {
        text: {
            default: '#fff',
            hover: primary
        }
    },
    big_button: {
        solid: {
            background: {
                hover: '#000',
                default: '#fff'
            },
            text: {
                hover: '#fff',
                default: '#000'
            }
        },
        outlined: {
            border: {
                hover: '#000',
                default: '#fff'
            },
            background: {
                hover: '#fff',
                default: '#000'
            },
            text: {
                hover: '#000',
                default: '#fff'
            }
        }
    },
    footer_tab: {
        text: '#fff'
    },
    money_status: {
        background: 'red'
    },
    radio_input: {
        icon: {
            default: 'rgba(58, 58, 58, 1)',
            active: primary
        },
        label: {
            default: 'rgba(58, 58, 58, 1)',
            active: 'rgba(245, 245, 245, 1)',
        }
    },
    form_input: {
        label: '#B8B8B8',
        text: palette.grey.fifty.light,
        background: {
            default: 'rgba(36, 36, 36, 1)'
        },
        border: {
            default: 'rgba(36, 36, 36, 1)',
            active: primary
        }
    },
    form_tabs: {
        tab: {
            background: {
                active: primary,
                default: 'rgba(36, 36, 36, 1)'
            },
            text: {
                active: palette.grey.fifty.light,
                default: '#ACAEAF'
            }
        },
    },
    colors: {
        green: '#37C759',
        blue: '#0179FF',
        red: '#FD3D2E',
        yellow: '#FEC601'
    },
    step_number: {
        background: {
            default: 'rgba(18, 18, 18, 1)'
        },
        shadow: 'rgba(32, 32, 32, 1) 0px 0px 0px 2px',
        default: 'rgba(38, 38, 38, 1)',
        text: '#B7BBBE',
        green: '#37C759',
        blue: '#0179FF',
        red: '#FD3D2E',
        yellow: '#FEC601'
    },
    account_dropdown: {
        background: palette.grey.nine_hundred.dark,
        icon: palette.grey.fifty.light
    },
    ticker: {
        label: '#B8B8B8'
    },
    header_tab: {
        background: {
            default: 'transparent',
            hover: 'rgba(18, 18, 18, 1)',
            active: 'rgba(38, 38, 38, 1)',
        },
        text: {
            default: 'rgba(150, 150, 150, 1)',
            hover: 'rgba(200, 200, 200, 1)',
            active: 'rgba(255, 255, 255, 1)',
        },
        indicator: '#B8B8B8',
        theme_icon: primary
    },
    // subheader: {
    //     background: palette.grey.fifty.light
    // },
    // status_bar: {
    //     border: palette.grey.nine_hundred.dark,
    //     background: {
    //         default: palette.grey.fifty.light,
    //         green: palette.green.five_hundred.primary,
    //         yellow: palette.yellow.five_hundred,
    //         amber: palette.amber.five_hundred
    //     }
    // },
    profile: {
        background: '#000',
        title: 'rgba(255, 255, 255, 1)',
        text: 'rgba(150, 150, 150, 1)',
        subtext: 'rgba(255, 255, 255, 1)'
    },
    profile_cover: {
        title: palette.grey.nine_hundred.dark,
        background: '#262A2C',
        label: {
            text: {
                default: palette.grey.nine_hundred.primary
            },
            icon: {
                default: palette.grey.nine_hundred.primary
            }
        }
    },
    form_select: {
        background: {
            default: palette.grey.nine_hundred.primary,
            hover: palette.grey.eight_hundred.primary
        },
        text: {
            default: palette.grey.fifty.light,
            placeholder: palette.grey.six_hundred.primary
        },
        icon: {
            default: palette.grey.seven_hundred.primary
        }
    },
    desktop_sidebar: {
        background: '#090A0C',
        border: '#2E3940'
    },
    desktop_header: {
        // background: palette.green.nine_hundred.dark,
        background: '#000',
        // background: 'rgba(26, 29, 26, 0.9)',
        // logo: palette.grey.nine_hundred.dark,
        logo: '#fff',
        // border: '#3B464C',
        border: '#192026',
        text: {
            default: palette.grey.nine_hundred.dark
        }
    },
    header: {
        background: '#000',
        border: `0 0 0 1px #262A2C`,
        shadow: '0 0 0 1px rgba(36, 36, 36, 1), 0 0 0 2px rgba(12, 12, 12, 1)'
    },
    inbox: {
        background: '#000'
        // background: 'linear-gradient(0deg, rgba(12,12,12,1) 0%, rgba(18,18,18,1) 100%)'
    },
    toolbar: {
        background: 'rgba(0, 0, 0, 1)',
        text: 'rgba(255, 255, 255, 1)'
    },
    settings_page: {
        background: '#262A2C',
        border: 'rgba(58, 58, 58, 1)',
        text: 'rgba(255, 255, 255, 1)',
        row: {
            background: {
                default: '#262A2C',
                hover: 'rgba(10, 10, 10, 1)'
            }
        }
    },
    wallet_page: {
        background: 'rgba(18, 18, 18, 1)'
    },
    logout_btn: {
        text: '#E9190F'
    },
    step_tab: {
        text: {
            preparing: '#3777FF',
            waiting: '#FFD43E',
            rejected: '#CE2D4F',
            default: 'rgba(180, 180, 180, 1)',
            active: 'rgba(255, 255, 255, 1)'
        },
        indicator: 'rgba(180, 180, 180, 1)'
    },
    step_header: {
        border: '#000',
        background: '#363739'
    },
    project_title: {
        text: 'rgba(255, 255, 255, 1)',
    },
    action_item: {
        background: 'rgba(36, 36, 36, 1)',
        // shadow: '#363739 0px 0px 0px 1px, #232729 0px 0px 0px 2px, #262A2C 0px 0px 8px 0px',
    },
    page_tab: {
        background: {
            default: 'rgba(58, 58, 58, 1)',
            hover: 'rgba(70, 70, 70, 1)',
            disabled: 'rgba(36, 36, 36, 1)'
        },
        icon: {
            default: 'rgba(255, 255, 255, 1)',
            hover: 'rgba(255, 255, 255, 1)',
            disabled: 'rgba(100, 100, 100, 1)'
        }
    },
    dashboard: {
        background: palette.grey.eight_hundred.dark,
        toolbar: palette.grey.eight_hundred.dark,
        text: palette.grey.six_hundred.primary,
        border: 'rgba(240, 240, 240, 1)',
        card: {
            background: 'rgba(0, 0, 0, 1)',
            border: {
                default: 'rgba(16, 16, 16, 1)',
                hover: 'rgba(16, 16, 16, 1)',
            },
            label: {
                text: 'rgba(220, 220, 220, 1)',
                icon: 'rgba(120, 120, 120, 1)',
                background: 'rgba(50, 50, 50, 1)'
            }
        }
    },
    sheet: {
        backdrop: palette.grey.nine_hundred.primary,
        sheet: palette.grey.eight_hundred.primary,
        text: palette.grey.fifty.light,
        border: palette.grey.seven_hundred.primary,
        header: {
            default: palette.grey.nine_hundred.primary,
            border: palette.grey.eight_hundred.primary,
        },
        footer: {
            default: palette.grey.eight_hundred.dark,
            border: palette.grey.eight_hundred.primary,
        }
    },
    slider: {
        label: palette.grey.three_hundred.primary
    },
    caret: {
        default: palette.blue.five_hundred.primary
    },
    mode_picker: {
        default: palette.grey.eight_hundred.primary,
        hover: palette.grey.eight_hundred.dark,
        text: palette.grey.three_hundred.primary,
        textHover: palette.grey.one_hundred.primary,
    },
    file_grid: {
        background: palette.grey.eight_hundred.dark,
        highlighter: 'rgba(33, 151, 243, 0.15)',
        highlighter_border: 'rgba(33, 151, 243, 0.25)'
    },
    main_nav: {
        text: {
            default: palette.grey.five_hundred.primary,
            hover: palette.grey.seven_hundred.primary,
            active: palette.grey.fifty.light,
        },
        default: palette.grey.nine_hundred.primary,
        hover: palette.grey.eight_hundred.primary,
        textHover: palette.grey.three_hundred.primary,
        active: palette.grey.seven_hundred.dark,
        activeText: palette.grey.fifty.light,
        activeHoverText: palette.grey.fifty.light,
        background: palette.grey.one_hundred.primary
    },
    menu: {
        text: palette.grey.five_hundred.primary,
        default: palette.grey.eight_hundred.dark,
        hover: palette.grey.eight_hundred.primary,
        textHover: palette.grey.three_hundred.primary,
        clicked: 'rgba(25, 118, 210, 0.4)',
        active: 'rgba(25, 118, 210, 0.25)',
        activeText: palette.blue.eight_hundred.primary,
        divider: palette.grey.eight_hundred.primary,
        shadow: 'rgba(158, 158, 158, 0.25)',
        border: palette.grey.eight_hundred.primary,
    },
    element_picker: {
        header: {
            background: {
                default: palette.grey.fifty.primary,
                hover: palette.grey.fifty.light,
            },
            text: {
                default: palette.grey.nine_hundred.dark,
            }
        },
        row: {
            background: {
                default: palette.grey.fifty.light,
                active: palette.grey.three_hundred.primary,
                hover: palette.grey.two_hundred.primary,
            },
            text: {
                default: palette.grey.nine_hundred.dark,
                active: palette.grey.nine_hundred.dark,
            }
        },
        shadow: {
            default: 'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.08) 0px 2px 5px 0px',
            hover: 'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.08) 0px 3px 9px 0px, rgba(60, 66, 87, 0.08) 0px 2px 5px 0px',
            active: 'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(33, 151, 243, 0.5) 0px 0px 0px 4px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.08) 0px 3px 9px 0px, rgba(60, 66, 87, 0.08) 0px 2px 5px 0px'
        }
    },
    series_button: {
        icon: palette.grey.five_hundred.primary,
        iconHover: palette.grey.six_hundred.primary,
    },
    text_secondary: '#AFB1B2',
    picker: {
        background: palette.grey.nine_hundred.primary,
        default: palette.grey.nine_hundred.primary,
        hover: palette.grey.seven_hundred.primary,
        text: palette.grey.three_hundred.primary,
        textHover: palette.grey.two_hundred.primary,
        shortcut: palette.grey.six_hundred.primary,
        shortcutHover: palette.grey.two_hundred.primary,
        active: palette.blue.seven_hundred.primary,
        activeText: palette.grey.fifty.light,
        activeShortcut: palette.blue.one_hundred.primary,
        // shadow: 'rgba(158, 158, 158, 0.1)',
        shadowActive: 'rgba(158, 158, 158, 0.25)',
        border: palette.grey.eight_hundred.dark,
        divider: palette.grey.seven_hundred.dark,
        shadow: 'rgba(0, 0, 0, 0.7)'
    },
    avatar: {
        green: palette.green.six_hundred,
        default: '#5755D5',
        text: 'rgba(255, 255, 255, 1)',
        background: {
            default: 'rgba(0, 0, 0, 1)',
            hover: primary
        }
    },
    splitter: {
        default: palette.grey.five_hundred.primary
    },
    toggle_button: {
        default: palette.grey.nine_hundred.primary,
        text: palette.grey.two_hundred.primary,
        active: palette.grey.eight_hundred.dark,
        activeText: palette.grey.fifty.primary,
        border: palette.blue.seven_hundred.primary
    },
    tasks_page: {
        background: palette.grey.eight_hundred.dark,
        text: palette.grey.fifty.light,
        sidebar: {
            background: palette.grey.nine_hundred.primary,
            border: palette.grey.eight_hundred.primary
        }
    },
    space_picker: {
        default: palette.grey.nine_hundred.primary,
        hover: palette.grey.eight_hundred.dark,
        text: palette.grey.fifty.light
    },
    add_view: {
        default: palette.grey.nine_hundred.primary,
        hover: palette.grey.eight_hundred.dark,
        text: palette.grey.fifty.light,
        textHover: palette.grey.fifty.light
    },
    channel: {
        background: palette.grey.fifty.light,
        border: palette.grey.three_hundred.primary,
        text: palette.grey.nine_hundred.dark,
        input: {
            border: palette.grey.four_hundred.primary,
            default: palette.grey.fifty.light,
            activeBorder: palette.grey.six_hundred.primary,
            activeToolbar: palette.grey.two_hundred.primary
        },
    },
    // indicator: {
    //     unread: palette.blue.six_hundred.primary,
    //     red: palette.red.six_hundred,
    //     green: palette.green.six_hundred,
    //     orange: palette.orange.six_hundred
    // },
    post_modal: {
        backdrop: 'rgba(0, 0, 0, 0.8)'
    },
    comments: {

    },
    card: {
        background: {
            default: palette.grey.six_hundred.light,
            active: palette.indigo.six_hundred
        },
        switch: {
            track: {
                on: {
                    default: palette.indigo.eight_hundred,
                },
                off: {
                    default: palette.grey.six_hundred.primary,
                }
            },
            text: {
                on: {
                    default: palette.indigo.three_hundred,
                },
                off: {
                    default: palette.grey.four_hundred.primary,
                }
            },
            thumb: {
                on: {
                    default: palette.indigo.four_hundred,
                },
                off: {
                    default: palette.grey.four_hundred.primary,
                }
            }
        },
        default: palette.blue_grey.fifty.primary,
        border: palette.blue_grey.one_hundred.primary,
        shadow: 'rgba(0, 10, 18, 0.05)',
        label: palette.grey.fifty.light
    },
    board: {
        backdrop: palette.grey.eight_hundred.primary,
        border: palette.grey.six_hundred.primary,
        column: palette.blue_grey.twenty_five.primary,
        card: palette.blue_grey.twenty_five.primary,
        shadow: palette.blue_grey.one_hundred.primary,
        title_focus: palette.grey.fifty.light,
        title_text: palette.blue_grey.nine_hundred.primary,
        title_text_focus: palette.blue_grey.nine_hundred.dark,
        button_active: palette.blue_grey.fifty.primary,
        button_icon: palette.blue_grey.four_hundred.primary,
        header: palette.grey.eight_hundred.primary,
        accent: {
            red: palette.red.four_hundred,
            orange: palette.orange.four_hundred,
            yellow: palette.amber.four_hundred,
            green: palette.green.four_hundred.primary,
            blue: palette.blue.four_hundred.primary,
        },
        tab: {
            icon: palette.blue_grey.one_hundred.primary
        }
    },
    dropdown: {
        background: palette.grey.fifty.light
    },
    mini_sidebar: {
        background: palette.grey.eight_hundred.dark,
        hover: palette.grey.seven_hundred.dark,
        active: palette.grey.six_hundred.dark,
        border: palette.grey.eight_hundred.primary,
        tab: {
            active: palette.blue_grey.seven_hundred.dark,
            default: palette.grey.seven_hundred.dark,
        },
        icon: {
            active: palette.grey.fifty.light,
            default: palette.grey.six_hundred.primary
        }
    },
    tooltip: {
        background: palette.grey.nine_hundred.dark,
        text: palette.grey.fifty.light,
        shadow: 'rgba(0, 0, 0, 0.5)'
    },
    explore: {
        background: palette.grey.nine_hundred.dark,
        header: {
            background: palette.blue_grey.nine_hundred.dark,
            border: palette.grey.nine_hundred.primary,
        }
    },
    home_sidebar: {
        background: palette.grey.nine_hundred.primary,
        border: palette.grey.two_hundred.primary,
        team_icon: {
            background: {
                default: palette.grey.two_hundred.primary
            },
        },
        team_link: {
            background: {
                default: palette.grey.nine_hundred.primary,
                active: palette.grey.three_hundred.primary,
                hover: palette.grey.fifty.primary,
            },
            text: {
                default: palette.grey.nine_hundred.dark,
                active: palette.grey.nine_hundred.dark,
                hover: palette.grey.nine_hundred.dark,
            },
            expand_icon: palette.grey.six_hundred.primary
        },
        tint_row: {
            background: {
                default: palette.blue.five_hundred.primary,
                active: palette.grey.nine_hundred.dark,
                hover: palette.grey.fifty.primary,
            },
            text: {
                default: palette.grey.nine_hundred.dark,
                active: palette.grey.fifty.light,
                hover: palette.grey.nine_hundred.dark,
            }
        },
        row: {
            background: {
                default: palette.grey.nine_hundred.primary,
                active: palette.grey.nine_hundred.dark,
                hover: palette.grey.fifty.primary,
            },
            text: {
                default: palette.grey.nine_hundred.dark,
                active: palette.grey.fifty.light,
                hover: palette.grey.nine_hundred.dark,
            }
        }
    },
    button: {
        default: palette.blue.seven_hundred.primary,
        hover: palette.blue.six_hundred.primary,
        text: palette.grey.fifty.light,
        textHover: palette.grey.fifty.light,
        shadow: 'rgba(0, 0, 0, 0.4)',
        shadowHover: 'rgba(0, 0, 0, 0.6)',
        border: palette.grey.six_hundred.primary,
        borderHover: palette.grey.eight_hundred.primary
    },
    // text: palette.grey.three_hundred.primary,
    // headerIcon: palette.grey.six_hundred.primary,
    // primaryBg: palette.grey.fifty.light,
    // navDropdown: palette.grey.fifty.light,
    // navDropdownShadow: '0 2px 6px rgba(0, 0, 0, 0.2)',
    // border: palette.grey.one_hundred.primary,
    // saved: palette.grey.one_hundred.dark,
    // error: palette.red.six_hundred,
    // linkDefault: palette.grey.nine_hundred.dark,
    // linkHover: palette.grey.eight_hundred.primary,
    // starDefault: palette.grey.six_hundred.primary,
    // starActive: palette.yellow.eight_hundred,
    // menuItemHover: palette.grey.fifty.dark,
    // menuItemActive: palette.blue.one_hundred,
    // menuItemClicked: palette.blue.three_hundred,
}