import ACTIONS from '../actions';

const initialState = []

const listsReducer = (state = initialState, action) => {
    switch(action.type) {
        case ACTIONS.NEW_TASK_LIST:
            return [...state, action.payload]
        case ACTIONS.REFRESH_LISTS:
            return [...action.payload.lists]
        default:
            return state
    }
}

export default listsReducer;