import ACTIONS from '../actions';

const initialState = {
    name: '',
    description: '',
    start_date: null,
    end_date: null,
    tribe: ''
}

const missionReducer = (state = initialState, action) => {
    switch(action.type) {
        case ACTIONS.LOAD_MISSION:
            return {
                ...state,
                mission_id: action.payload.mission_id,
                name: action.payload.name,
                description: action.payload.description,
                start_date: action.payload.start_date,
                end_date: action.payload.end_date,
            }
        default:
            return state
    }
}

export default missionReducer;