import ACTIONS from '../actions';

const initialState = {
    last_pressed: {
        key: '',
        source: '',
        timestamp: ''
    }
}

const keyLogReducer = (state = initialState, action) => {
    switch(action.type) {
        case ACTIONS.LOG_KEY:
            return {
                ...state,
                last_pressed: action.payload.last_pressed
            }
        default:
            return state
    }
}

export default keyLogReducer;