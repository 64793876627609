import ACTIONS from '../actions';

// const initialState = [
//     {
//         type: 'Scene Heading',
//         content: ''
//     }
// ]

const initialState = [
    {
        type: 'Scene Heading',
        content: 'INT. MICHAEL SCARN\'S OFFICE -- DAY'
    },
    {
        type: 'Action',
        content: 'AGENT MICHAEL SCARN, ruggedly handsome without even trying, relaxes at his desk, feet up, reviewing a case file. His gun rests next to his FBI badge, which glints in the office\'s buzzing florescent light. Dressed in a suit, he\'s loosened his tie and undone some of the buttons, revealing his hairy chest.'
    },
    {
        type: 'Action',
        content: 'The closed door flies open as CATHERINE ZETA-JONES bursts into the office. Acting on instinct, Michael Scarn leans back in his chair and rams his feet forward, tipping his desk over onto the floor. Michael catches his gun in midair, then crouches down behind the overturned desk for cover.'
    },
    {
        type: 'Character',
        content: 'CATHERINE ZETA-JONES'
    },
    {
        type: 'Parenthetical',
        content: '(covering face with folders)'
    },
    {
        type: 'Dialogue',
        content: 'Don\'t shoot, it\'s me, Catherine Zeta-Jones! I have some messages for you!'
    },
    {
        type: 'Character',
        content: 'MICHAEL SCARN'
    },
    {
        type: 'Dialogue',
        content: 'Catherine Zeta-Jones! Didn\'t I tell you to knock!?'
    },
    {
        type: 'Character',
        content: 'CATHERINE ZETA-JONES'
    },
    {
        type: 'Dialogue',
        content: 'I\'m so sorry! Maybe I can make it up to you.'
    },
    {
        type: 'Action',
        content: 'Seductively, she inches nearer, stepping over the mess.'
    },
]

const paragraphsReducer = (state = initialState, action) => {
    switch(action.type) {
        case ACTIONS.NEW_PARAGRAPH_BEFORE:
            return [
                ...state.slice(0, action.payload.index),
                {
                    content: '',
                    type: action.payload.type
                },
                ...state.slice(action.payload.index)
            ]
        case ACTIONS.NEW_PARAGRAPH_AFTER:
            return [
                ...state.slice(0, action.payload.index+1),
                {
                    content: '',
                    type: action.payload.type
                },
                ...state.slice(action.payload.index+1)
            ]
        case ACTIONS.SPLIT_PARAGRAPH:
            return [
                ...state.slice(0, action.payload.index),
                {
                    content: state[action.payload.index].content.slice(0, action.payload.start),
                    type: action.payload.type
                },
                {
                    content: state[action.payload.index].content.slice(action.payload.start),
                    type: action.payload.type
                },
                ...state.slice(action.payload.index+1)
            ]
        case ACTIONS.MERGE_PARAGRAPHS:
            return [
                ...state.slice(0, action.payload.index-1),
                {
                    content: state[action.payload.index-1].content + state[action.payload.index].content,
                    type: action.payload.type
                },
                ...state.slice(action.payload.index+1)
            ]
        case ACTIONS.REMOVE_PARAGRAPH:
            return state.filter((item, index) => index !== action.payload.index)
        case ACTIONS.REMOVE_CHARACTER:
            return state.map((item, index) => {
                if (index !== action.payload.index) return item
                var cursor_pos = action.payload.start
                var newContent = 
                    [item.content.slice(0, cursor_pos-1),
                    item.content.slice(cursor_pos)].join('')
                item.content = newContent
                return item
            })
        case ACTIONS.UPDATE_PARAGRAPH:
            return state.map((item, index) => {
                if (index !== action.payload.index) return item
                var cursor_pos = action.payload.offset.end
                var newContent = 
                    [item.content.slice(0, cursor_pos), 
                    action.payload.key, 
                    item.content.slice(cursor_pos)].join('')
                item.content = newContent
                return item
            })
        case ACTIONS.PARAGRAPH_TYPE:
            return {
                ...state,
                type: action.payload
            }
        case ACTIONS.ELEMENT_TYPE_PARAGRAPH:
            return state.map((item, index) => {
                if (index !== action.payload.index) return item
                item.type = action.payload.type
                if (action.payload.type === 'Parenthetical') {
                    item.content = `(${item.content})`
                } else if(action.payload.strip_parentheticals) {
                    // strip ()
                }
                return item
            })
        case ACTIONS.CLEAR_PARAGRAPHS:
            return [
                {
                    type: 'Scene Heading',
                    content: ''
                }
            ]
        default:
            return state
    }
}

export default paragraphsReducer