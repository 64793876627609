import ACTIONS from '../actions';

const initialState = []

const commentReducer = (state = initialState, action) => {
    switch(action.type) {
        case ACTIONS.FETCH_COMMENTS:
            return [
                ...state,
                ...action.payload.comments
            ]
        case ACTIONS.CLEAR_COMMENTS:
            return []
        default:
            return state
    }
}

export default commentReducer;