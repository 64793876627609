import ACTIONS from '../actions';

const initialState = {
    name: '',
    description: '',
    isAdmin: false
}

const tribeReducer = (state = initialState, action) => {
    switch(action.type) {
        case ACTIONS.LOAD_TRIBE:
            return {
                ...state,
                tribe_id: action.payload.tribe_id,
                name: action.payload.name,
                description: action.payload.description,
                isAdmin: action.payload.isAdmin,
            }
        default:
            return state
    }
}

export default tribeReducer;