import React, {useState} from 'react';
import axios from 'axios';
import {useParams} from 'react-router-dom';
import {showErrMsg, showSuccessMsg} from '../../utils/notification/Notification';
import {isLength, isMatch, isEmpty} from '../../utils/validation/Validation';
import { useTheme } from '../../../theme/useTheme';
import SubmitButton from '../../global/form/SubmitButton';
import TextInput from '../../global/form/TextInput';

const initialState = {
    password: '',
    password_confirm: '',
    err: '',
    success: ''
}

const ResetPassword = () => {

    const [data, setData] = useState(initialState);
    const {id} = useParams();

    const {password, password_confirm, err, success} = data;

    const handleChangeInput = (name, value) => {
        setData({...data, [name]:value, err: '', success: ''});
    }

    const handleSubmit = async(e) => {
        e.preventDefault();

        if(isEmpty(password) || isEmpty(password)) {
            return setData({...data, err: 'Please fill in all fields.', success: ''});
        }

        if(isLength(password)) {
            return setData({...data, err: 'Password must be at least 6 characters.', success: ''});
        }

        if(!isMatch(password, password_confirm)) {
            return setData({...data, err: 'Passwords do not match.', success: ''});
        }

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/user/reset`, {password}, {
                headers: {Authorization: id}
            });
            return setData({...data, err: '', success: res.data.message});
        } catch (err) {
            err.response.data.message && setData({...data, err: err.response.data.message, success: ''});
        }
    }

    const {landing} = useTheme()

    const styles = {
        container: {
            width: '400px',
            marginTop: '-100px'
        },
        footer: {
            fontSize: '15px',
            fontWeight: '400',
            textAlign: 'center',
            marginTop: '50px',
            color: landing.text
        },
        link: {
            fontWeight: '600',
            color: landing.text
        }
    }

    return (
        <div style={styles.container}>

            {err && showErrMsg(err)}
            {success && showSuccessMsg(success)}

            <form onSubmit={(e) => handleSubmit(e)}>

                <TextInput
                    label="New Password"
                    required={true}
                    type="password"
                    value={password}
                    onChange={(value) => handleChangeInput('password', value)}
                />

                <TextInput
                    label="Confirm Password"
                    required={true}
                    type="password"
                    value={password_confirm}
                    onChange={(value) => handleChangeInput('password_confirm', value)}
                />

                <SubmitButton>
                    Change Password
                </SubmitButton>
            </form>
        </div>
    );
};

export default ResetPassword;