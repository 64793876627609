import ACTIONS from '../actions';

const initialState = ''

const searchReducer = (state = initialState, action) => {
    switch(action.type) {
        case ACTIONS.SEARCH_QUERY:
            return action.payload
        default:
            return state
    }
}

export default searchReducer;