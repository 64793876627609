import React from 'react';
import NotificationFlash from './NotificationFlash';

export const showErrMsg = (msg) => {
    return(
        <NotificationFlash
            message={msg}
        />
    )
}

export const showSuccessMsg = (msg) => {
    return(
        <NotificationFlash
            message={msg}
        />
    )
}