import ACTIONS from '../actions';

const initialState = {
    open: false,
    minimized: false,
    chat_id: '',
    messages: [
        {
            id: '12345',
            time: '2013-09-05 15:34:00',
            text: 'This is message',
            user: {
                name: 'Michael Scott',
                avatar: null,
                id: '98765'
            }
        },
        {
            id: '12345',
            time: '2013-09-05 15:34:00',
            text: 'This is a message',
            user: {
                name: 'Michael Scott',
                avatar: null,
                id: '98765'
            }
        },
        {
            id: '12345',
            time: '2013-09-05 15:34:00',
            text: 'This is a message that is kind of long and will take up multiple lines and also max out the width of the message.',
            user: {
                name: 'Michael Scott',
                avatar: null,
                id: '12345'
            }
        },
        {
            id: '12345',
            time: '2013-09-05 15:34:00',
            text: 'This is a message',
            user: {
                name: 'Michael Scott',
                avatar: null,
                id: '12345'
            }
        },
        {
            id: '12345',
            time: '2013-09-05 15:34:00',
            text: 'This is a message',
            user: {
                name: 'Michael Scott',
                avatar: null,
                id: '98765'
            }
        },
        {
            id: '12345',
            time: '2013-09-05 15:34:00',
            text: 'This is a message',
            user: {
                name: 'Michael Scott',
                avatar: null,
                id: '12345'
            }
        },
        {
            id: '12345',
            time: '2013-09-05 15:34:00',
            text: 'This is a message',
            user: {
                name: 'Michael Scott',
                avatar: null,
                id: '98765'
            }
        },
        {
            id: '12345',
            time: '2013-09-05 15:34:00',
            text: 'This is a message',
            user: {
                name: 'Michael Scott',
                avatar: null,
                id: '98765'
            }
        },
        {
            id: '12345',
            time: '2013-09-05 15:34:00',
            text: 'This is a message',
            user: {
                name: 'Michael Scott',
                avatar: null,
                id: '12345'
            }
        },
        {
            id: '12345',
            time: '2013-09-05 15:34:00',
            text: 'This is a message',
            user: {
                name: 'Michael Scott',
                avatar: null,
                id: '12345'
            }
        },
        {
            id: '12345',
            time: '2013-09-05 15:34:00',
            text: 'This is a message',
            user: {
                name: 'Michael Scott',
                avatar: null,
                id: '12345'
            }
        },
        {
            id: '12345',
            time: '2013-09-05 15:34:00',
            text: 'This is a message',
            user: {
                name: 'Michael Scott',
                avatar: null,
                id: '98765'
            }
        },
        {
            id: '12345',
            time: '2013-09-05 15:34:00',
            text: 'This is a message',
            user: {
                name: 'Michael Scott',
                avatar: null,
                id: '98765'
            }
        },
        {
            id: '12345',
            time: '2013-09-05 15:34:00',
            text: 'This is a message',
            user: {
                name: 'Michael Scott',
                avatar: null,
                id: '12345'
            }
        },
        {
            id: '12345',
            time: '2013-09-05 15:34:00',
            text: 'This is a message',
            user: {
                name: 'Michael Scott',
                avatar: null,
                id: '12345'
            }
        },
        {
            id: '12345',
            time: '2013-09-05 15:34:00',
            text: 'This is a message',
            user: {
                name: 'Michael Scott',
                avatar: null,
                id: '98765'
            }
        },
        {
            id: '12345',
            time: '2013-09-05 15:34:00',
            text: 'This is a message',
            user: {
                name: 'Michael Scott',
                avatar: null,
                id: '98765'
            }
        },
        {
            id: '12345',
            time: '2013-09-05 15:34:00',
            text: 'This is a message',
            user: {
                name: 'Michael Scott',
                avatar: null,
                id: '98765'
            }
        },
        {
            id: '12345',
            time: '2013-09-05 15:34:00',
            text: 'This is a message',
            user: {
                name: 'Michael Scott',
                avatar: null,
                id: '12345'
            }
        },
        {
            id: '12345',
            time: '2013-09-05 15:34:00',
            text: 'This is a message',
            user: {
                name: 'Michael Scott',
                avatar: null,
                id: '98765'
            }
        },
        {
            id: '12345',
            time: '2013-09-05 15:34:00',
            text: 'This is a message',
            user: {
                name: 'Michael Scott',
                avatar: null,
                id: '12345'
            }
        },
        {
            id: '12345',
            time: '2013-09-05 15:34:00',
            text: 'This is a message',
            user: {
                name: 'Michael Scott',
                avatar: null,
                id: '98765'
            }
        },
        {
            id: '12345',
            time: '2013-09-05 15:34:00',
            text: 'This is a message',
            user: {
                name: 'Michael Scott',
                avatar: null,
                id: '98765'
            }
        },
        {
            id: '12345',
            time: '2013-09-05 15:34:00',
            text: 'This is a message',
            user: {
                name: 'Michael Scott',
                avatar: null,
                id: '12345'
            }
        },
        {
            id: '12345',
            time: '2013-09-05 15:34:00',
            text: 'This is a message',
            user: {
                name: 'Michael Scott',
                avatar: null,
                id: '12345'
            }
        },
        {
            id: '12345',
            time: '2013-09-05 15:34:00',
            text: 'This is a message',
            user: {
                name: 'Michael Scott',
                avatar: null,
                id: '12345'
            }
        },
        {
            id: '12345',
            time: '2013-09-05 15:34:00',
            text: 'This is a message',
            user: {
                name: 'Michael Scott',
                avatar: null,
                id: '12345'
            }
        },
        {
            id: '12345',
            time: '2013-09-05 15:34:00',
            text: 'This is the first message',
            user: {
                name: 'Michael Scott',
                avatar: null,
                id: '98765'
            }
        }
    ]
}

const chatReducer = (state = initialState, action) => {
    switch(action.type) {
        case ACTIONS.TOGGLE_CHAT:
            return {
                ...state,
                open: action.payload.open
            }
        case ACTIONS.TOGGLE_CHAT_MINIMIZE:
            return {
                ...state,
                minimized: action.payload.minimized
            }
        case ACTIONS.SET_CHAT_ID:
            return {
                ...state,
                chat_id: action.payload.chat_id
            }
        default:
            return state
    }
}

export default chatReducer;