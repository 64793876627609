import React from 'react';
import { Link } from 'react-router-dom';
import SignUpButton from './SignUpButton';
import { useTheme } from '../../theme/useTheme';
import BigLogo from './BigLogo';
import BigButtonSolid from './BigButtonSolid';
import BigButtonOutlined from './BigButtonOutlined';
import MainFooter from '../footer/MainFooter';
import BigTriangle from './BigTriangle';
import AuthContainer from './AuthContainer';

const Home = () => {

    const {landing} = useTheme()

    const styles = {
        container: {
            backgroundColor: landing.hero.background,
            position: 'fixed',
            height: '100%',
            width: '100%',
            overflow: 'auto',
            // padding: '0px 15px 0px 15px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
        },
        // header: {
        //     height: '60px',
        //     width: '100%',
        //     display: 'flex',
        //     alignItems: 'center',
        //     justifyContent: 'space-between',
        //     flexDirection: 'row',
        // },
        // content: {
        //     height: '100%',
        //     display: 'flex',
        //     alignItems: 'center',
        //     justifyContent: 'center',
        //     flexDirection: 'column',
        // },
        label: {
            color: landing.header.text,
            fontSize: '18px',
            fontWeight: '600',
            textAlign: 'center'
        },
    }

    return (
        <div style={styles.container}>


            <AuthContainer>
                <BigLogo />

                <BigButtonSolid
                    label="Log In"
                    to="/log-in"
                />

                <BigButtonOutlined
                    label="Sign Up"
                    to="/sign-up"
                />

            </AuthContainer>

            <MainFooter />

            {/* <BigTriangle /> */}
        </div>
    );
};

export default Home;