import ACTIONS from '../actions';

const initialState = []

const rectsReducer = (state = initialState, action) => {
    switch(action.type) {
        case ACTIONS.UPDATE_RECTS:
            return [
                ...state.slice(0, action.payload.index),
                action.payload.rects,
                ...state.slice(action.payload.index+1)
            ]
        default:
            return state
    }
}

export default rectsReducer;