import ACTIONS from '../actions';

const initialState = {
    expanded: true,
    min: 1,
    max: 2,
    count: 2,
    projects: [
        // {
        //     label: "Some New Gig",
        //     message_read: true,
        //     starred: false,
        //     read: false,
        //     invitation: true,
        //     id: "1234",
        //     description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis feugiat, elit eget sollicitudin aliquet, nisl ipsum luctus sapien, scelerisque tincidunt nulla est et nibh. Sed nec urna non nibh tincidunt facilisis ut et tellus. Maecenas faucibus pharetra diam. Mauris vehicula sit amet nisi a condimentum. Mauris eget viverra nunc. Pellentesque a finibus diam. Pellentesque dictum felis quam, eget efficitur lectus accumsan a. Aliquam nec rutrum est. Aenean rhoncus posuere leo, sit amet egestas urna pretium in. Pellentesque nec ante sed leo volutpat ullamcorper id sed lectus. Aliquam ac hendrerit libero. Duis auctor interdum magna eu laoreet. Nulla erat eros, ultricies in quam a, euismod finibus sem. Maecenas vulputate nunc et molestie malesuada.",
        //     last_message: "",
        //     user_to: {
        //         name: 'Summer Films',
        //         username: 'summer'
        //     },
        //     user_from: {
        //         name: 'Dwight Schrute',
        //         username: 'michael_scott'
        //     },
        //     amount: 700000,
        //     files: [],
        //     steps: [
        //         {
        //             label: 'Agree',
        //             page: 'agree',
        //             status: 'preparing',
        //         },
        //         {
        //             label: 'Work',
        //             page: 'work',
        //             status: 'not_started'
        //         },
        //         {
        //             label: 'Done',
        //             page: 'done',
        //             status: 'not_started'
        //         },
        //     ],
        // },
    ],
}

const projectsInvitedReducer = (state = initialState, action) => {
    switch(action.type) {
        case ACTIONS.FETCH_PROJECTS:
            return [...action.payload.projects]
        case ACTIONS.TOGGLE_UNREAD:
            return {
                ...state,
                expanded: action.payload.expanded
            }
        default:
            return state
    }
}

export default projectsInvitedReducer;