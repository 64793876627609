import React, {useState} from 'react';
import axios from 'axios';
import {isEmail} from '../../utils/validation/Validation';
import {showErrMsg, showSuccessMsg} from '../../utils/notification/Notification';
import FakeLink from '../../global/FakeLink';
import TextInput from '../../global/form/TextInput';
import SubmitButton from '../../global/form/SubmitButton';
import ModalTitle from './ModalTitle';
import { useTheme } from '../../../theme/useTheme';
import { Link } from 'react-router-dom';
import AuthContainer from '../AuthContainer';

const initialState = {
    email: '',
    err: '',
    success: ''
}

const ForgotPassword = (props) => {

    const [data, setData] = useState(initialState);
    const {email, err, success} = data;

    const handleChangeInput = (name, value) => {
        setData({...data, [name]:value, err: '', success: ''});
    }

    const handleSubmit = async(e) => {
        e.preventDefault();

        if(!isEmail(email)) {
            return setData({...data, err: 'Invalid email address.', success: ''});
        }

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/user/forgot`, {email})
            return setData({...data, err: '', success: res.data.message});
        } catch (err) {
            err.response.data.message && setData({...data, err: err.response.data.message, success: ''});
        }
    }

    const {landing} = useTheme()

    const styles = {
        footer: {
            fontSize: '15px',
            fontWeight: '400',
            textAlign: 'center',
            marginTop: '50px',
            color: landing.text
        },
        link: {
            fontWeight: '600',
            color: landing.text
        }
    }

    return (
        <AuthContainer>
            {err && showErrMsg(err)}
            {success && showSuccessMsg(success)}

            <form onSubmit={(e) => handleSubmit(e)}>
                
                <TextInput
                    label="Email Address"
                    required={true}
                    type="email"
                    value={email}
                    onChange={(value) => handleChangeInput('email', value)}
                />

                <SubmitButton>
                    Send Password Reset Link
                </SubmitButton>

                <div style={styles.footer}>

                    <Link to="/log-in" style={styles.link}>
                        Log In
                    </Link>

                </div>
                
            </form>
        </AuthContainer>
    );
};

export default ForgotPassword;