import React, {useState} from 'react';
import axios from 'axios';
import {showErrMsg} from '../../utils/notification/Notification';
import {isEmpty, isEmail, isLength} from '../../utils/validation/Validation';
import TextInput from '../../global/form/TextInput';
import FakeLink from '../../global/FakeLink';
import SubmitButton from '../../global/form/SubmitButton';
import { useTheme } from '../../../theme/useTheme';
import { Link } from 'react-router-dom';
import AuthContainer from '../AuthContainer';

const initialState = {
    displayName: '',
    username: '',
    email: '',
    password: '',
    err: '',
    success: ''
}

const Register = (props) => {

    const [user, setUser] = useState(initialState);

    const {displayName, username, email, password, err, success} = user;

    const handleChangeInput = (name, value) => {
        setUser({...user, [name]:value, err: '', success: ''})
    }

    const handleSubmit = async(e) => {
        e.preventDefault();
        
        if(isEmpty(displayName) || isEmpty(password)) {
            return setUser({...user, err: "Please fill in all fields.", success: ""});
        }

        if(!isEmail(email)) {
            return setUser({...user, err: "Invalid email address.", success: ""});
        }

        if(isLength(password)) {
            return setUser({...user, err: "Password must be at least 6 characters.", success: ""});
        }
        
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/users/register`, {
                user: {
                    displayName, username, email, password
                }
            });

            setUser({...user, err: '', success: (res.data.email && res.data.email.length > 0)})
        } catch (err) {
            err.response && 
            setUser({...user, err: err.response.data.message, success: ''})
        }
    }

    const {landing} = useTheme()

    const styles = {
        footer: {
            fontSize: '15px',
            fontWeight: '400',
            textAlign: 'center',
            marginTop: '50px',
            color: landing.text
        },
        link: {
            fontWeight: '600',
            color: landing.text
        },
        success: {
            fontWeight: '600',
            fontSize: '18px',
            color: landing.text,
            width: '100%',
            textAlign: 'center'
        }
    }

    const form = () => (
            <form
                onSubmit={handleSubmit}
            >

                <TextInput
                    label="Full Name"
                    required={true}
                    value={displayName}
                    onChange={(value) => handleChangeInput('displayName', value)}
                />

                <TextInput
                    label="Username"
                    required={true}
                    value={username}
                    onChange={(value) => handleChangeInput('username', value)}
                />

                <TextInput
                    label="Email Address"
                    required={true}
                    type="email"
                    value={email}
                    onChange={(value) => handleChangeInput('email', value)}
                />

                <TextInput
                    label="Password"
                    required={true}
                    type="password"
                    value={password}
                    onChange={(value) => handleChangeInput('password', value)}
                />

                <SubmitButton>
                    Sign Up
                </SubmitButton>

                <div style={styles.footer}>

                    Already have an account? <Link to="/log-in" style={styles.link}>Log In</Link>

                </div>

            </form>
    )

    const successMessage = () => (
        <div style={styles.success}>
            Thanks for signing up! Check your email to verify your account!
        </div>
    )

    return (
        <AuthContainer>

            {err && showErrMsg(err)}
            {success ? successMessage() : form()}

        </AuthContainer>
    );
};

export default Register;
