import {palette} from '../palette';

let header_bg = palette.grey.eight_hundred.primary
let tab_bg = palette.grey.nine_hundred.primary
let tab_bg_active = palette.grey.nine_hundred.dark

let primary = '#0478FF'

export const day = {
    activation: {
        text: '#000',
        background: '#fff'
    },
    landing: {
        text: 'rgba(0, 0, 0, 1)',
        link: 'rgba(0, 0, 0, 1)',
        logo: 'rgba(0, 0, 0, 1)',
        subtext: 'rgba(0, 0, 0, 1)',
        header: {
            background: palette.grey.fifty.light,
            text: '#000',
            link: '#C2C2C2',
            back_button: {
                background: {
                    default: 'rgba(240, 240, 240, 1)',
                    hover: 'rgba(230, 230, 230, 1)',
                },
                icon: palette.grey.nine_hundred.dark,
                esc: 'rgba(58, 58, 58, 1)'
            }
        },
        hero: {
            background: palette.grey.fifty.light,
            title: palette.grey.nine_hundred.dark,
            description: palette.grey.six_hundred.primary,
            section: palette.blue_grey.fifty.primary
        },
        beta_btn: {
            background: {
                default: palette.grey.nine_hundred.dark
            },
            text: palette.grey.fifty.light
        },
        features: {
            background: palette.blue_grey.fifty.primary,
        },
        footer: {
            background: palette.grey.fifty.light,
            text: palette.grey.nine_hundred.dark,
            divider: palette.grey.six_hundred.primary,
        },
        modal: {
            backdrop: 'rgba(255, 255, 255, 0.9)',
        },
        modal_btn: {
            default: palette.grey.nine_hundred.dark,
            hover: palette.grey.eight_hundred.primary,
            text: palette.grey.six_hundred.primary,
            text_hover: palette.grey.eight_hundred.primary,
            shadow: 'rgba(0, 0, 0, 0.4)',
            shadowHover: 'rgba(0, 0, 0, 0.6)',
            border: palette.grey.six_hundred.primary,
            border_hover: palette.grey.eight_hundred.primary
        },
        submit: {
            default: palette.blue.five_hundred.primary,
            hover: palette.blue.six_hundred.primary,
            text: palette.grey.fifty.light,
            textHover: palette.grey.fifty.light,
        },
        textInput: {
            default: palette.blue_grey.fifty.primary,
            active: palette.grey.fifty.primary,
            border: palette.blue_grey.fifty.primary,
            borderActive: palette.grey.six_hundred.primary
        },
        // logo: {
        //     text: palette.grey.nine_hundred.dark,
        //     version: {
        //         default: palette.blue_grey.two_hundred.primary,
        //         text: palette.grey.eight_hundred.primary,
        //     }
        // }
    },
    text_input: {
        background: {
            default: '#fff',
            active: palette.grey.fifty.primary,
        },
        color: '#000',
        border: {
            default: 'rgba(200, 200, 200, 1)',
            active: 'rgba(0, 0, 0, 1)',
        }
    },
    contract_preview: {
        background: {
            default: '#202C33',
            hover: '#192026'
        },
        badge: {
            background: '#B7BBBE',
            icon: '#202C33'
        },
        expand: '#6B7D8A',
        title: '#B7BBBE',
        subtitle: '#B7BBBE',
    },
    project: {
        background: palette.grey.fifty.light,
        border: '#E8E9ED',
        background_gradient: {
            top: palette.grey.fifty.primary,
            bottom: palette.grey.one_hundred.primary,
        }
    },
    steps: {
        // backdrop: palette.blue_grey.twenty_five.primary,
        backdrop: '#F7F6F3',
        shadow: {
            default: palette.grey.two_hundred.primary,
            hover: palette.grey.three_hundred.primary,
        }
    },
    step: {
        label: {
            default: palette.grey.nine_hundred.primary
        },
        background: {
            default: palette.grey.fifty.light,
            hover: palette.grey.fifty.primary
        },
        shadow: {
            default: palette.grey.three_hundred.primary,
            hover: palette.grey.three_hundred.primary,
        },
        label: {
            blue: palette.blue.four_hundred.primary,
            green: palette.green.four_hundred.primary,
            amber: palette.amber.four_hundred
        }
    },
    direct_message: {
        border: palette.blue_grey.one_hundred.primary,
        backdrop: palette.blue_grey.fifty.light,
        background: {
            default: palette.blue_grey.one_hundred.primary,
            hover: palette.blue_grey.eight_hundred.dark,
            active: palette.blue_grey.seven_hundred.dark,
        }
    },
    add_tribe_page: {
        background: palette.grey.fifty.light
    },
    // mission_page: {
    //     background: palette.grey.fifty.light,
    //     preview: {
    //         background: {
    //             default: palette.blue_grey.nine_hundred.primary,
    //         },
    //         text: palette.blue_grey.seven_hundred.primary
    //     },
    //     header: {
    //         background: palette.grey.fifty.primary,
    //         border: palette.blue_grey.one_hundred.primary,
    //     }
    // },
    mission_card: {
        label: palette.blue_grey.seven_hundred.primary,
        background: {
            default: palette.blue_grey.fifty.primary
        },
        border: {
            default: palette.blue_grey.fifty.primary,
            hover: palette.blue_grey.two_hundred.primary
        }
    },
    
    main_footer: {
        background: palette.grey.fifty.light,
        text: palette.blue_grey.nine_hundred.primary,
        border: palette.blue_grey.one_hundred.primary,
        tab: {
            background: {
                default: palette.grey.fifty.light,
                active: palette.grey.fifty.light,
            },
            icon: {
                default: palette.grey.seven_hundred.primary,
                active: palette.green.six_hundred,
            }
        }
    },
    right_now: {
        background: palette.grey.fifty.light
    },
    // up_next: {
    //     header: {
    //         border: palette.blue_grey.one_hundred.primary,
    //         background: palette.blue_grey.two_hundred.primary,
    //         text: palette.grey.fifty.light,
    //     },
    //     backdrop: palette.blue_grey.three_hundred.primary,
    //     row: {
    //         background: {
    //             default: palette.blue_grey.two_hundred.primary,
    //             active: palette.grey.eight_hundred.primary
    //         },
    //         text: {
    //             default: palette.grey.fifty.light
    //         }
    //     }
        
    // },
    mission_about: {
        background: palette.grey.nine_hundred.dark
    },
    tool_item: {
        background: {
            default: palette.grey.fifty.light,
            active: palette.grey.two_hundred.primary,
            hover: palette.grey.one_hundred.primary
        },
        text: {
            unread: palette.grey.one_hundred.primary,
            default: palette.grey.nine_hundred.dark,
            active: palette.grey.nine_hundred.dark,
            hover: palette.grey.nine_hundred.dark,
        },
        unread: palette.blue.six_hundred.primary
    },
    
    main_modal: {
        text: palette.grey.fifty.light,
        background: palette.blue_grey.nine_hundred.primary,
        shadow: 'rgba(38, 50, 56, 0.25)'
    },
    overlay: {
        background: palette.grey.fifty.light,
        text: palette.grey.nine_hundred.dark,
        // backdrop: 'rgba(0, 0, 0, 0.8)'
        backdrop: 'rgba(27, 29, 26, 0.9)'
    },
    message: {
        background: {
            default: palette.grey.fifty.light,
            hover: palette.grey.two_hundred.primary,
        },
        time: palette.grey.six_hundred.primary
    },
    inbox_page: {
        body: {
            background: '#F7F6F3'
        },
        header: {
            background: 'rgba(255, 255, 255, 0.95)',
            // background: 'rgba(215, 216, 216, 0.9)',
            border: palette.blue_grey.one_hundred.primary,
        },
        content: {
            background: palette.grey.fifty.light
        },
        add_thread: {
            background: {
                default: palette.blue_grey.fifty.primary,
                hover: palette.blue_grey.two_hundred.primary,
            },
            text: {
                default: palette.blue_grey.six_hundred.primary,
                hover: palette.blue_grey.nine_hundred.primary,
            },
            button: {
                background: {
                    default: palette.blue_grey.one_hundred.primary,
                    hover: palette.blue_grey.three_hundred.primary,
                },
                icon: {
                    default: palette.blue_grey.six_hundred.primary,
                    hover: palette.blue_grey.nine_hundred.primary,
                }
            }
        },
        search: {
            icon: palette.grey.fifty.light,
            text: palette.grey.fifty.light,
            border: palette.blue_grey.one_hundred.primary,
            background: {
                default: palette.blue_grey.fifty.primary,
                active: palette.grey.seven_hundred.dark
            }
        },
        // sidebar: {
        //     background: palette.grey.fifty.light,
        //     border: palette.grey.three_hundred.primary,
        //     section: {
        //         background: palette.grey.fifty.light,
        //         text: {
        //             default: palette.blue_grey.six_hundred.primary,
        //             hover: palette.blue_grey.four_hundred.primary
        //         }
        //     },
        //     row: {
        //         background: {
        //             default: palette.grey.fifty.light,
        //             active: palette.blue_grey.two_hundred.primary,
        //             hover: palette.blue_grey.two_hundred.primary
        //         },
        //         text: {
        //             unread: palette.blue_grey.nine_hundred.primary,
        //             default: palette.blue_grey.six_hundred.primary,
        //             active: palette.blue_grey.nine_hundred.primary,
        //             hover: palette.blue_grey.eight_hundred.primary,
        //         },
        //         unread: palette.blue.six_hundred.primary
        //     }
        // },
        footer: {
            background: palette.grey.fifty.light,
            spacer: palette.grey.five_hundred.primary,
            input: {
                background: {
                    default: palette.grey.two_hundred.primary,
                },
                border: {
                    default: palette.grey.seven_hundred.primary,
                    active: palette.grey.six_hundred.primary,
                },
                text: palette.grey.nine_hundred.dark
            },
            submit_btn: {
                background: {
                    default: palette.grey.eight_hundred.dark,
                    hover: palette.grey.seven_hundred.dark,
                    active: palette.grey.seven_hundred.dark
                },
                icon: {
                    default: '#4030FF',
                    hover: palette.grey.fifty.light,
                    active: palette.grey.eight_hundred.dark
                }
            },
            file_btn: {
                background: {
                    default: palette.grey.nine_hundred.dark,
                    hover: palette.blue_grey.eight_hundred.dark,
                    active: palette.blue_grey.eight_hundred.dark
                },
                icon: {
                    default: palette.grey.seven_hundred.primary,
                    hover: palette.grey.six_hundred.primary,
                    active: palette.grey.eight_hundred.dark
                }
            },
            message: palette.blue_grey.seven_hundred.primary
        }
    },
    timeline: {
        line: {
            default: palette.blue_grey.seven_hundred.primary
        }
    },
    shadow: '0 0 0 1px rgb(136 152 170 / 10%), 0 15px 35px 0 rgb(49 49 93 / 10%), 0 5px 15px 0 rgb(0 0 0 / 8%)',
    textInput: {
        default: palette.blue_grey.fifty.primary,
        hover: palette.grey.two_hundred.primary,
        active: palette.grey.fifty.light,
        border: palette.blue_grey.one_hundred.primary,
        label: palette.blue_grey.six_hundred.primary,
        text: palette.grey.nine_hundred.dark,
        optionActive: palette.blue.four_hundred.primary,
        optionActiveText: palette.grey.fifty.light,
        shadow: 'rgba(0, 0, 0, 0.15)'
    },
    form_items: {
        footer_border: palette.grey.three_hundred.primary,
        footer_shadow: 'rgba(0, 0, 0, 0.25)',
        footer_background: palette.grey.fifty.light,
        result_default: palette.grey.fifty.light,
        result_hover: palette.grey.one_hundred.primary,
    },
    form: {
        background: palette.grey.fifty.light,
        add_user: {
            text: {
                default: palette.grey.nine_hundred.dark,
                open: palette.grey.fifty.light
            },
            background: {
                open: palette.grey.nine_hundred.dark,
                hover: palette.grey.one_hundred.primary,
                default: palette.grey.fifty.light
            },
            checkbox: palette.grey.nine_hundred.dark,
            label: palette.grey.six_hundred.primary,
            lookup_icon: palette.grey.six_hundred.primary,
            dropdown: {
                background: palette.grey.fifty.light,
                highlight: palette.blue.fifty.primary
            }
        },
        tab: {
            border: {
                active: palette.grey.nine_hundred.dark,
                default: palette.grey.three_hundred.primary
            },
            text: {
                active: palette.grey.nine_hundred.dark,
                default: palette.grey.seven_hundred.primary
            }
        }
    },
    big_modal: {
        bg: 'rgba(255, 255, 255, 0.8)',
        shadow: 'rgba(0, 0, 0, 0.15)',
        border: palette.blue_grey.fifty.primary,
        panel: palette.blue_grey.twenty_five.primary,
        tab_text: palette.blue_grey.six_hundred.primary
    },
    global: {
        background: palette.grey.fifty.light,
    },
    your_projects: {
        border: palette.blue_grey.one_hundred.primary,
        backdrop: palette.blue_grey.twenty_five.primary,
    },
    project_nav: {
        default: palette.grey.fifty.light,
        text: palette.grey.eight_hundred.primary,
        hover: palette.grey.two_hundred.primary,
        active: palette.blue.one_hundred.primary,
        textActive: palette.blue.seven_hundred.primary,
    },
    video_slider: {
        headerColor: 'red'
    },
    nav_button: {
        default: palette.grey.fifty.primary,
        hover: palette.grey.fifty.dark,
        active: palette.teal.one_hundred.primary,
        textActive: palette.teal.five_hundred.dark,
        text: palette.grey.six_hundred.primary
    },
    media_thumb: {
        background: {
            default: '#F0F2F4'
        }
    },
    step_nav: {
        background: {
            default: '#E8E7E4',
            hover: '#BDBCB8',
            disabled: '#E8E7E4'
        },
        icon: {
            default: '#37352F',
            hover: '#37352F',
            disabled: '#E8E7E4'
        }
    },
    project_content: {
        background: 'rgba(255, 255, 255, 1)',
        content: {
            background: 'rgba(240, 240, 240, 1)',
        },
        // border: `#ECECEC`,
        // shadow: `0 0 0 1px rgba(230, 230, 230, 1)`,
        shadow: `0 0 0 1px rgba(230, 230, 230, 1), 0 0 0 2px rgba(255, 255, 255, 0.5), 0 0 6px rgba(230, 230, 230, 1)`,
        text: '#000'
    },
    file_search: {
        border: {
            // default: `0 0 0 1px rgba(230, 230, 230, 1), 0 0 0 2px rgba(255, 255, 255, 0.5), 0 0 6px rgba(230, 230, 230, 1)`,
            default: 'rgba(240, 240, 240, 1)',
            hover: palette.grey.three_hundred.primary,
        },
        background: {
            default: 'rgba(245, 245, 245, 1)',
            // default: '#EFEFEF',
            // active: palette.grey.two_hundred.primary
            active: '#EFEFEF'
        },
        // icon: palette.grey.nine_hundred.dark,
        icon: {
            active: 'rgba(0, 0, 0, 1)',
            default: 'rgba(0, 0, 0, 1)',
        },
        text: palette.grey.nine_hundred.dark
    },
    search_bar: {
        default: palette.grey.one_hundred.primary,
        active: palette.grey.fifty.light,
        activeBorder: palette.grey.fifty.dark,
        icon: palette.grey.six_hundred.primary,
    },
    sidebar: {
        hover_indicator: {
            background: palette.grey.four_hundred.primary
        },
        add_tribe: {
            background: {
                default: palette.blue_grey.six_hundred.primary,
                hover: palette.blue_grey.two_hundred.primary,
            },
            text: {
                default: palette.blue_grey.four_hundred.primary,
                hover: palette.blue_grey.nine_hundred.primary,
            },
            button: {
                background: {
                    default: palette.blue_grey.five_hundred.primary,
                    hover: palette.blue_grey.three_hundred.primary,
                },
                icon: {
                    default: palette.blue_grey.two_hundred.primary,
                    hover: palette.blue_grey.nine_hundred.primary,
                }
            }
        },
    },
    logo: {
        text: palette.grey.nine_hundred.dark,
        beta: {
            default: palette.blue_grey.two_hundred.primary,
            text: palette.blue_grey.eight_hundred.primary,
        }
    },
    user_link: {
        default: palette.grey.six_hundred.primary,
        hover: palette.teal.five_hundred
    },
    toggle_menu: {
        main: {
            background: {
                default: 'rgba(0, 0, 0, 1)',
                hover: 'rgba(0, 0, 0, 1)',
                active: 'purple',
            },
            border: {
                default: 'rgba(0, 0, 0, 1)',
                hover: 'rgba(0, 0, 0, 1)',
                active: 'purple',
            },
            text: {
                // default: '#37352F',
                default: 'rgba(255, 255, 255, 1)',
                hover: '#fff',
                active: '#fff',
            },
            shadow: {
                default: 'rgba(0, 0, 0, 0.7) 0px 0px 0px 2px, rgba(0, 0, 0, 0.6) 0px 0px 0px 4px, rgba(0, 0, 0, 0.4) 0px 0px 20px 0px',
                hover: 'rgba(0, 0, 0, 0.6) 0px 0px 0px 3px',
            }
        },
        arrow_container: {
            background: {
                default: '#090A0C',
                hover: '#090A0C',
                active: '#090A0C',
            },
            text: {
                default: '#6A7F8A',
                hover: '#fff',
                active: '#fff',
            }
        },
        dropdown: {
            background: '#fff',
            shadow: 'rgb(15 15 15 / 5%) 0px 0px 0px 1px, rgb(15 15 15 / 10%) 0px 3px 6px, rgb(15 15 15 / 20%) 0px 9px 24px'
        }
    },
    back_btn: {
        background: {
            default: 'rgba(255, 255, 255, 1)',
            hover: '#E8E7E4'
        },
        text: {
            default: 'rgba(100, 100, 100, 1)',
            hover: 'rgba(18, 18, 18, 1)',
        }
    },
    group_toggle: {
        background: {
            default: 'rgba(255, 255, 255, 0)',
            hover: 'rgba(230, 230, 230, 1)',
        },
        text: {
            default: 'rgba(150, 150, 150, 1)',
            hover: '#000000',
        }
    },
    star: {
        default: {
            default: 'rgba(200, 200, 200, 1)',
            hover: 'rgba(150, 150, 150, 1)',
        },
        active: {
            default: 'rgba(0, 0, 0, 1)',
            hover: 'rgba(0, 0, 0, 1)'
        }
    },
    more_button: {
        background: {
            hover: 'tan'
        },
        icon: {
            default: '#6A7F8A',
            hover: 'red',
        }
    },
    project_card: {
        background: {
            unread: {
                default: 'rgba(250, 250, 250, 1)',
                hovering: '#FAFAFA',
            },
            read: {
                default: 'rgba(240, 240, 240, 1)',
                hovering: '#D3D7D7'
            }
        },
        text: palette.grey.nine_hundred.dark,
        border: {
            default: '#F5F7F7',
            hover: palette.grey.four_hundred.primary
        },
        shadow: {
            // default: '0px 1px 40px 0 rgb(89 96 115 / 8%), 0px 1px 2px 0 rgb(89 96 115 / 12%)',
            default: `0 0 0 1px rgba(236, 236, 236, 1), 0 0 2px 2px rgba(255, 255, 255, 1)`,
            hover: palette.grey.three_hundred.primary,
        },
        label: {
            read: 'rgba(150, 150, 150, 1)',
            unread: 'rgba(0, 0, 0, 1)',
        }
    },
    send_message_btn: {
        background: {
            default: '#4030FF',
            hover: '#5D4EFC'
        }
    },
    bucket: {
        background: palette.grey.fifty.light
    },
    quick_project_form: {
        background: palette.grey.fifty.light,
        // background: '#12141D',
        title: palette.grey.nine_hundred.dark,
        button: {
            background: {
                default: '#37352F',
                hover: '#37352F',
                // default: palette.grey.fifty.light,
                // hover: palette.grey.fifty.light,
            },
            text: {
                // hover: palette.green.nine_hundred.primary,
                // default: palette.green.six_hundred,
                default: palette.grey.fifty.light,
                hover: palette.grey.fifty.light
            },
            border: {
                hover: palette.green.nine_hundred.primary,
                default: palette.green.six_hundred,
            }
        },
        text_button: {
            text: {
                hover: palette.grey.four_hundred.primary,
                default: palette.grey.six_hundred.primary,
            }
        },
        shadow: {
            default: palette.grey.three_hundred.primary,
            hover: palette.grey.four_hundred.primary,
        }
    },
    theme_toggle: {
        text: {
            default: '#000',
            hover: primary
        }
    },
    big_button: {
        solid: {
            background: {
                hover: '#fff',
                default: '#000'
            },
            text: {
                hover: '#000',
                default: '#fff'
            }
        },
        outlined: {
            border: {
                hover: '#000',
                default: '#000'
            },
            background: {
                hover: '#000',
                default: '#fff'
            },
            text: {
                hover: '#fff',
                default: '#000'
            }
        }
    },
    footer_tab: {
        text: '#000'
    },
    money_status: {
        background: 'red'
    },
    radio_input: {
        icon: {
            default: 'rgba(230, 230, 230, 1)',
            active: primary
        },
        label: {
            default: 'rgba(230, 230, 230, 1)',
            active: 'rgba(18, 18, 18, 1)',
        }
    },
    form_input: {
        label: palette.grey.seven_hundred.primary,
        text: '#37352F',
        background: {
            default: '#EFEFEF'
        },
        border: {
            default: '#EFEFEF',
            active: '#37352F'
        }
    },
    form_tabs: {
        tab: {
            background: {
                // pay: '#37352F',
                // charge: palette.red.six_hundred,
                default: '#EFEFEF',
                active: '#37352F'
            },
            text: {
                active: palette.grey.fifty.light,
                default: '#37352F'
            }
        },
    },
    colors: {
        green: '#37C759',
        blue: '#0179FF',
        red: '#FD3D2E',
        yellow: '#FEC601'
    },
    step_number: {
        background: {
            default: palette.grey.fifty.light,
        },
        text: '#B7BBBE',
        green: '#37C759',
        blue: '#0179FF',
        red: '#FD3D2E',
        yellow: '#FEC601'
    },
    account_dropdown: {
        background: '#EFEFEF',
        icon: palette.grey.nine_hundred.dark
    },
    ticker: {
        label: '#37352F'
    },
    header_tab: {
        background: {
            default: 'rgba(255, 255, 255, 1)',
            hover: 'rgba(255, 255, 255, 1)',
            active: 'rgba(245, 245, 245, 1)',
        },
        text: {
            default: 'rgba(150, 150, 150, 1)',
            hover: 'rgba(0, 0, 0, 1)',
            active: 'rgba(0, 0, 0, 1)',
        },
        icon: {
            default: palette.grey.seven_hundred.primary
        },
        indicator: 'red'
    },
    profile: {
        background: '#000',
        title: 'rgba(255, 255, 255, 1)',
        text: 'rgba(150, 150, 150, 1)',
        subtext: 'rgba(255, 255, 255, 1)'
    },
    profile_cover: {
        title: palette.grey.nine_hundred.dark,
        background: palette.grey.one_hundred.primary,
        label: {
            text: {
                default: palette.grey.nine_hundred.primary
            },
            icon: {
                default: palette.grey.nine_hundred.primary
            }
        }
    },
    form_select: {
        background: {
            default: palette.grey.three_hundred.primary,
            hover: palette.grey.four_hundred.primary
        },
        text: {
            default: palette.grey.nine_hundred.dark,
            placeholder: palette.grey.five_hundred.primary
        },
        icon: {
            default: palette.grey.seven_hundred.primary
        }
    },
    // desktop_sidebar: {
    //     background: palette.grey.fifty.light,
    // },
    desktop_header: {
        // background: palette.grey.fifty.light,
        background: palette.grey.fifty.light,
        // background: 'rgba(255, 255, 255, 0.9)',
        // logo: palette.grey.nine_hundred.dark,
        logo: 'rgba(0, 0, 0, 1)',
        border: '#E8E9ED',
        text: {
            default: palette.grey.nine_hundred.dark
        }
    },
    header: {
        background: 'rgba(255, 255, 255, 1)',
        border: `0 0 0 1px rgba(236, 236, 236, 1)`
        // border: `0 0 12px 0 rgba(242, 242, 242, 1)`
    },
    inbox: {
        // background: 'linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(250, 250, 250, 1) 100%)'
        background: 'rgba(255, 255, 255, 1)'
    },
    toolbar: {
        background: 'rgba(255, 255, 255, 1)'
    },
    settings_page: {
        background: 'rgba(255, 255, 255, 1)',
        border: 'rgba(230, 230, 230, 1)',
        text: 'rgba(0, 0, 0, 1)',
        label: 'rgba(150, 150, 150, 1)',
        row: {
            background: {
                default: 'rgba(255, 255, 255, 1)',
                hover: 'rgba(245, 245, 245, 1)'
            }
        }
    },
    wallet_page: {
        background: 'rgba(255, 255, 255, 1)'
    },
    logout_btn: {
        text: '#E9190F'
    },
    step_tab: {
        text: {
            preparing: '#3777FF',
            waiting: '#FFD53E',
            rejected: '#CE2D4F',
            read: 'rgba(150, 150, 150, 1)',
            unread: 'rgba(0, 0, 0, 1)',
            default: 'rgba(100, 100, 100, 1)',
            active: 'rgba(18, 18, 18, 1)'
        },
        indicator: 'rgba(150, 150, 150, 1)'
    },
    step_header: {
        border: 'rgba(230, 230, 230, 1)',
        background: '#EBEBEB'
    },
    project_title: {
        text: 'rgba(18, 18, 18, 1)',
    },
    action_item: {
        background: 'rgba(236, 236, 236, 1)'
    },
    page_tab: {
        background: {
            default: 'rgba(58, 58, 58, 1)',
            hover: 'rgba(70, 70, 70, 1)',
            disabled: 'rgba(36, 36, 36, 1)'
        },
        icon: {
            default: 'rgba(255, 255, 255, 1)',
            hover: 'rgba(255, 255, 255, 1)',
            disabled: 'rgba(100, 100, 100, 1)'
        }
    },
    dashboard: {
        background: palette.grey.eight_hundred.dark,
        toolbar: palette.grey.eight_hundred.dark,
        text: palette.grey.six_hundred.primary,
        border: 'rgba(255, 255, 255, 1)',
        card: {
            background: 'rgba(255, 255, 255, 1)',
            border: {
                default: 'rgba(16, 16, 16, 1)',
                hover: 'rgba(16, 16, 16, 1)',
            },
            label: {
                text: 'rgba(80, 80, 80, 1)',
                background: 'rgba(240, 240, 240, 1)',
            }
        }
    },
    sheet: {
        backdrop: palette.grey.fifty.primary,
        sheet: palette.grey.fifty.light,
        text: palette.grey.nine_hundred.dark,
        border: palette.grey.three_hundred.primary,
        header: {
            default: palette.grey.fifty.light,
            border: palette.grey.two_hundred.primary,
        },
        footer: {
            default: palette.grey.fifty.primary,
            border: palette.grey.one_hundred.primary,
        }
    },
    slider: {
        label: palette.grey.nine_hundred.primary
    },
    caret: {
        default: palette.blue.five_hundred.primary
    },
    mode_picker: {
        default: palette.grey.fifty.primary,
        hover: palette.grey.three_hundred.primary,
        text: palette.grey.eight_hundred.primary,
        textHover: palette.grey.nine_hundred.primary,
    },
    file_grid: {
        background: palette.grey.fifty.light,
        highlighter: 'rgba(33, 151, 243, 0.15)',
        highlighter_border: 'rgba(33, 151, 243, 0.25)'
    },
    main_nav: {
        text: {
            default: palette.grey.five_hundred.primary,
            hover: palette.grey.seven_hundred.primary,
            active: palette.grey.nine_hundred.dark,
        },
        background: {
            active: palette.grey.fifty.primary
        },
        default: palette.grey.two_hundred.primary,
        hover: palette.grey.fifty.primary,
        textHover: palette.grey.nine_hundred.primary,
        active: palette.grey.fifty.light,
        activeText: palette.grey.nine_hundred.dark,
        activeHoverText: palette.grey.nine_hundred.dark,
        shadow: palette.grey.four_hundred.primary,
        border: palette.grey.two_hundred.primary,
        borderHover: palette.grey.three_hundred.primary,
        borderActive: palette.grey.fifty.light,
    },
    menu: {
        text: palette.grey.eight_hundred.primary,
        default: palette.grey.two_hundred.primary,
        hover: palette.grey.two_hundred.primary,
        textHover: palette.blue.five_hundred.primary,
        clicked: palette.blue.one_hundred.primary,
        active: palette.blue.fifty.primary,
        activeText: palette.blue.five_hundred.primary,
        divider: palette.grey.three_hundred.primary,
        shadow: 'rgba(0, 0, 0, 0.25)',
        border: palette.grey.three_hundred.primary,
    },
    text_secondary: '#37352F',
    avatar: {
        green: palette.green.six_hundred,
        default: '#5755D5',
        text: 'rgba(255, 255, 255, 1)',
        background: {
            default: 'rgba(0, 0, 0, 1)',
            hover: primary
        }
    },
    card: {
        background: {
            default: palette.grey.six_hundred.light,
            active: palette.indigo.six_hundred
        },
        switch: {
            track: {
                on: {
                    default: palette.indigo.eight_hundred,
                },
                off: {
                    default: palette.grey.six_hundred.primary,
                }
            },
            text: {
                on: {
                    default: palette.indigo.three_hundred,
                },
                off: {
                    default: palette.grey.four_hundred.primary,
                }
            },
            thumb: {
                on: {
                    default: palette.indigo.four_hundred,
                },
                off: {
                    default: palette.grey.four_hundred.primary,
                }
            }
        },
        default: palette.blue_grey.fifty.primary,
        border: palette.blue_grey.one_hundred.primary,
        shadow: 'rgba(0, 10, 18, 0.05)',
        label: palette.grey.fifty.light
    },
    // board: {
    //     backdrop: palette.blue_grey.twenty_five.primary,
    //     border: palette.blue_grey.one_hundred.primary,
    //     column: palette.blue_grey.twenty_five.primary,
    //     card: palette.blue_grey.twenty_five.primary,
    //     shadow: palette.blue_grey.one_hundred.primary,
    //     title_focus: palette.grey.fifty.light,
    //     title_text: palette.blue_grey.nine_hundred.primary,
    //     title_text_focus: palette.blue_grey.nine_hundred.dark,
    //     button_active: palette.blue_grey.fifty.primary,
    //     button_icon: palette.blue_grey.four_hundred.primary,
    //     header: palette.grey.fifty.light,
    //     accent: {
    //         red: palette.red.four_hundred,
    //         orange: palette.orange.four_hundred,
    //         yellow: palette.amber.four_hundred,
    //         green: palette.green.four_hundred.primary,
    //         blue: palette.blue.four_hundred.primary,
    //     },
    //     tab: {
    //         icon: palette.blue_grey.one_hundred.primary
    //     }
    // },
    dropdown: {
        background: palette.grey.fifty.light
    },
    // mini_sidebar: {
    //     background: palette.grey.fifty.light,
    //     hover: palette.blue_grey.fifty.primary,
    //     active: palette.grey.three_hundred.primary,
    //     border: palette.grey.three_hundred.primary,
    //     tab: {
    //         active: palette.blue_grey.five_hundred.primary,
    //         default: palette.grey.fifty.light
    //     },
    //     icon: {
    //         active: palette.grey.fifty.light,
    //         default: palette.blue_grey.five_hundred.primary
    //     }
    // },
    tooltip: {
        background: palette.grey.nine_hundred.dark,
        text: palette.grey.fifty.light,
        shadow: 'rgba(0, 0, 0, 0.5)'
    },
    explore: {
        background: palette.grey.fifty.light,
        header: {
            background: palette.grey.fifty.light,
            border: palette.grey.two_hundred.primary,
        }
    },
    home_sidebar: {
        background: palette.grey.fifty.light,
        border: palette.grey.two_hundred.primary,
        team_icon: {
            background: {
                default: palette.grey.two_hundred.primary
            },
        },
        team_link: {
            background: {
                default: palette.grey.eight_hundred.primary,
                active: palette.grey.three_hundred.primary,
                hover: palette.grey.fifty.primary,
            },
            text: {
                default: palette.grey.nine_hundred.dark,
                active: palette.grey.nine_hundred.dark,
                hover: palette.grey.nine_hundred.dark,
            },
            expand_icon: palette.grey.six_hundred.primary
        },
        tint_row: {
            background: {
                default: palette.blue.five_hundred.primary,
                active: palette.grey.nine_hundred.dark,
                hover: palette.grey.fifty.primary,
            },
            text: {
                default: palette.grey.nine_hundred.dark,
                active: palette.grey.fifty.light,
                hover: palette.grey.nine_hundred.dark,
            }
        },
        row: {
            background: {
                default: palette.grey.eight_hundred.primary,
                active: palette.grey.nine_hundred.dark,
                hover: palette.grey.fifty.primary,
            },
            text: {
                default: palette.grey.nine_hundred.dark,
                active: palette.grey.fifty.light,
                hover: palette.grey.nine_hundred.dark,
            }
        }
    },
    button: {
        default: palette.grey.nine_hundred.dark,
        hover: palette.blue.six_hundred.primary,
        text: palette.grey.fifty.light,
        textHover: palette.grey.fifty.light,
        shadow: 'rgba(0, 0, 0, 0.4)',
        shadowHover: 'rgba(0, 0, 0, 0.6)',
        border: palette.grey.six_hundred.primary,
        border_hover: palette.grey.eight_hundred.primary
    },
}