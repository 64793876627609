export const palette = {
    red: {
        fifty: '#ffebee',
        one_hundred: '#ffcdd2',
        two_hundred: '#ef9a9a',
        three_hundred: '#e57373',
        four_hundred: '#ef5350',
        five_hundred: '#f44336',
        six_hundred: '#e53935',
        seven_hundred: '#d32f2f',
        eight_hundred: '#c62828',
        nine_hundred: '#b71c1c'
    },
    pink: {
        fifty: '#fce4ec',
        one_hundred: '#f8bbd0',
        two_hundred: '#f48fb1',
        three_hundred: '#f06292',
        four_hundred: '#ec407a',
        five_hundred: '#e91e63',
        six_hundred: '#d81b60',
        seven_hundred: '#c2185b',
        eight_hundred: '#ad1457',
        nine_hundred: '#880e4f'
    },
    purple: {
        fifty: '#f3e5f5',
        one_hundred: '#e1bee7',
        two_hundred: '#ce93d8',
        three_hundred: '#ba68c8',
        four_hundred: '#ab47bc',
        five_hundred: '#9c27b0',
        six_hundred: '#8e24aa',
        seven_hundred: '#7b1fa2',
        eight_hundred: '#6a1b9a',
        nine_hundred: '#4a148c'
    },
    deep_purple: {
        fifty: '#ede7f6',
        one_hundred: '#d1c4e9',
        two_hundred: '#b39ddb',
        three_hundred: '#9575cd',
        four_hundred: '#7e57c2',
        five_hundred: '#673ab7',
        six_hundred: '#5e35b1',
        seven_hundred: '#512da8',
        eight_hundred: '#4527a0',
        nine_hundred: '#311b92'
    },
    indigo: {
        fifty: '#e8eaf6',
        one_hundred: '#c5cae9',
        two_hundred: '#9fa8da',
        three_hundred: '#7986cb',
        four_hundred: '#5c6bc0',
        five_hundred: '#3f51b5',
        six_hundred: '#3949ab',
        seven_hundred: '#303f9f',
        eight_hundred: '#283593',
        nine_hundred: {
            primary: '#18227C',
            light: '#5249AC',
            dark: '#02004F'
        },
    },
    blue: {
        fifty: {
            primary: '#e3f2fd',
            light: '#ffffff',
            dark: '#b1bfca'
        },
        one_hundred: {
            primary: '#bbdefb',
            light: '#eeffff',
            dark: '#8aacc8'
        },
        two_hundred: {
            primary: '#90caf9',
            light: '#c3fdff',
            dark: '#5d99c6'
        },
        three_hundred: {
            primary: '#64b5f6',
            light: '#9be7ff',
            dark: '#2286c3'
        },
        four_hundred: {
            primary: '#42a5f5',
            light: '#80d6ff',
            dark: '#0077c2'
        },
        five_hundred: {
            primary: '#2196f3',
            light: '#6ec6ff',
            dark: '#0069c0'
        },
        six_hundred: {
            primary: '#1e88e5',
            light: '#6ab7ff',
            dark: '#005cb2'
        },
        seven_hundred: {
            primary: '#1976d2',
            light: '#63a4ff',
            dark: '#004ba0'
        },
        eight_hundred: {
            primary: '#1565c0',
            light: '#5e92f3',
            dark: '#003c8f'
        },
        nine_hundred: {
            primary: '#0d47a1',
            light: '#5472d3',
            dark: '#002171'
        }
    },
    light_blue: {
        fifty: '#e1f5fe',
        one_hundred: '#b3e5fc',
        two_hundred: '#81d4fa',
        three_hundred: '#4fc3f7',
        four_hundred: '#29b6f6',
        five_hundred: '#03a9f4',
        six_hundred: '#039be5',
        seven_hundred: '#0288d1',
        eight_hundred: '#0277bd',
        nine_hundred: '#01579b'
    },
    cyan: {
        fifty: '#e0f7fa',
        one_hundred: '#b2ebf2',
        two_hundred: '#80deea',
        three_hundred: '#4dd0e1',
        four_hundred: '#26c6da',
        five_hundred: '#00bcd4',
        six_hundred: '#00acc1',
        seven_hundred: '#0097a7',
        eight_hundred: '#00838f',
        nine_hundred: '#006064'
    },
    teal: {
        fifty: '#e0f2f1',
        one_hundred: {
            primary: '#b2dfdb',
            light: '#e5ffff',
            dark: '#82ada9'
        },
        two_hundred: '#80cbc4',
        three_hundred: '#4db6ac',
        four_hundred: '#26a69a',
        five_hundred:  {
            primary: '#009688',
            light: '#52c7b8',
            dark: '#00675b'
        },
        six_hundred: '#00897b',
        seven_hundred: '#00796b',
        eight_hundred: '#00695c',
        nine_hundred:  {
            primary: '#004d40',
            light: '#39796b',
            dark: '#00251a'
        },
    },
    green: {
        fifty: '#e8f5e9',
        one_hundred: {
            primary: '#c8e6c9',
            light: '#fbfffc',
            dark: '#97b498'
        },
        two_hundred: {
            primary: '#a5d6a7',
            light: '#d7ffd9',
            dark: '#75a478'
        },
        three_hundred: {
            primary: '#81c784',
            light: '#b2fab4',
            dark: '#519657'
        },
        four_hundred: {
            primary: '#66bb6a',
            light: '#98ee99',
            dark: '#338a3e'
        },
        five_hundred: {
            primary: '#4caf50',
            light: '#80e27e',
            dark: '#087f23'
        },
        six_hundred: '#43a047',
        seven_hundred: '#388e3c',
        eight_hundred: '#2e7d32',
        nine_hundred: {
            primary: '#1b5e20',
            light: '#4c8c4a',
            dark: '#003300'
        },
    },
    light_green: {
        fifty: '#f1f8e9',
        one_hundred: '#dcedc8',
        two_hundred: '#c5e1a5',
        three_hundred: '#aed581',
        four_hundred: '#9ccc65',
        five_hundred: '#8bc34a',
        six_hundred: '#7cb342',
        seven_hundred: '#689f38',
        eight_hundred: '#558b2f',
        nine_hundred: '#33691e'
    },
    lime_green: {
        fifty: '#f9fbe7',
        one_hundred: '#f0f4c3',
        two_hundred: '#e6ee9c',
        three_hundred: '#dce775',
        four_hundred: '#d4e157',
        five_hundred: '#cddc39',
        six_hundred: '#c0ca33',
        seven_hundred: '#afb42b',
        eight_hundred: '#9e9d24',
        nine_hundred: '#827717'
    },
    yellow: {
        fifty: '#fffde7',
        one_hundred: '#fff9c4',
        two_hundred: '#fff59d',
        three_hundred: '#fff176',
        four_hundred: '#ffee58',
        five_hundred: '#ffeb3b',
        six_hundred: '#fdd835',
        seven_hundred: '#fbc02d',
        eight_hundred: '#f9a825',
        nine_hundred: '#f57f17'
    },
    amber: {
        fifty: '#fff8e1',
        one_hundred: '#ffecb3',
        two_hundred: '#ffe082',
        three_hundred: '#ffd54f',
        four_hundred: '#ffca28',
        five_hundred: '#ffc107',
        six_hundred: '#ffb300',
        seven_hundred: '#ffa000',
        eight_hundred: '#ff8f00',
        nine_hundred: '#ff6f00'
    },
    orange: {
        fifty: '#fff3e0',
        one_hundred: '#ffe0b2',
        two_hundred: '#ffcc80',
        three_hundred: '#ffb74d',
        four_hundred: '#ffa726',
        five_hundred: '#ff9800',
        six_hundred: '#fb8c00',
        seven_hundred: '#f57c00',
        eight_hundred: '#ef6c00',
        nine_hundred: '#e65100'
    },
    deep_orange: {
        fifty: '#fbe9e7',
        one_hundred: '#ffccbc',
        two_hundred: '#ffab91',
        three_hundred: '#ff8a65',
        four_hundred: '#ff7043',
        five_hundred: '#ff5722',
        six_hundred: '#f4511e',
        seven_hundred: '#e64a19',
        eight_hundred: '#d84315',
        nine_hundred: '#bf360c'
    },
    brown: {
        fifty: '#efebe9',
        one_hundred: '#d7ccc8',
        two_hundred: '#bcaaa4',
        three_hundred: '#a1887f',
        four_hundred: '#8d6e63',
        five_hundred: '#795548',
        six_hundred: '#6d4c41',
        seven_hundred: '#5d4037',
        eight_hundred: '#4e342e',
        nine_hundred: '#3e2723'
    },
    grey: {
        fifty: {
            primary: '#fafafa',
            light: '#ffffff',
            dark: '#c7c7c7'
        },
        one_hundred: {
            primary: '#f5f5f5',
            light: '#ffffff',
            dark: '#c2c2c2'
        },
        two_hundred: {
            primary: '#eeeeee',
            light: '#ffffff',
            dark: '#bcbcbc'
        },
        three_hundred: {
            primary: '#e0e0e0',
            light: '#ffffff',
            dark: '#aeaeae'
        },
        four_hundred: {
            primary: '#bdbdbd',
            light: '#efefef',
            dark: '#8d8d8d'
        },
        five_hundred: {
            primary: '#9e9e9e',
            light: '#cfcfcf',
            dark: '#707070'
        },
        six_hundred: {
            primary: '#757575',
            light: '#a4a4a4',
            dark: '#494949'
        },
        seven_hundred: {
            primary: '#616161',
            light: '#8e8e8e',
            dark: '#373737'
        },
        eight_hundred: {
            primary: '#424242',
            light: '#6d6d6d',
            dark: '#1b1b1b'
        },
        nine_hundred: {
            primary: '#212121',
            light: '#484848',
            dark: '#000000'
        },
    },
    blue_grey: {
        twenty_five: {
            primary: '#F6F7F8',
            light: 'rgba(237, 239, 241, 0.3)'
        },
        fifty: {
            primary: '#eceff1',
            light: '#ffffff',
            dark: '#babdbe'
        },
        one_hundred: {
            primary: '#CFD8DB',
            light: '#ffffff',
            dark: '#9EA7AA'
        },
        two_hundred: {
            primary: '#B1BEC5',
            light: '#E2F1F8',
            dark: '#818E95'
        },
        three_hundred: {
            primary: '#91A3AE',
            light: '#C2D4E0',
            dark: '#63747F'
        },
        four_hundred: {
            primary: '#798F9C',
            light: '#A8BFCE',
            dark: '#4C626E'
        },
        five_hundred: {
            primary: '#617C8B',
            light: '#8FACBB',
            dark: '#34505E'
        },
        six_hundred: {
            primary: '#546e7a',
            light: '#819ca9',
            dark: '#29434e'
        },
        seven_hundred: {
            primary: '#455964',
            light: '#718692',
            dark: '#1D303A'
        },
        eight_hundred: {
            primary: '#37474f',
            light: '#62727b',
            dark: '#102027'
        },
        nine_hundred: {
            primary: '#263238',
            light: '#4f5b62',
            dark: '#000a12'
        }
    },
    black: '#000000',
    white: '#ffffff'
}